@use "../global" as *;
//会社案内
#aboutus {
    .contents {
        padding-bottom: 0;
    }
    h2 {
        padding-top: 0;
    }
    h3 {
        padding-top: 0;
        padding-bottom: 0;
    }
    .message {
        margin-top: 10px;
        .text {
            width: 790px;
            margin: 0 auto 90px;
            letter-spacing: 0.25em;
            @include for-tb-sm() {
                width: 100%;
            }
            @include for-sp() {
                margin: 0 auto 50px;
            }
            p {
                &:not(:first-child) {
                    padding-top: 50px;
                    @include for-sp() {
                        padding-top: 25px;
                    }
                }
            }
        }
    }
    .activity {
        .activity-list {
            text-align: left;
            width: fit-content;
            margin: auto;
            font-size: 2.4rem;
            @include for-tb() {
                font-size: 2rem;
            }
            @include for-sp() {
                font-size: 1.6rem;
                line-height: 2;
            }
            li {
                padding-left: 80px;
                position: relative;
                margin-bottom: 20px;
                @include for-tb-sm() {
                    padding-left: 25px;
                }
                @include for-sp() {
                    padding-left: 20px;
                    font-size: 1.7rem;
                }
                &::before {
                    display: inline-block;
                    width: 14px;
                    height: 14px;
                    border-radius: 50%;
                    border: solid 3px $BTN_BLUE;
                    position: absolute;
                    left: 55px;
                    top: 25px;
                    content: "";
                    @include for-tb() {
                        top: 19px;
                    }
                    @include for-tb-sm() {
                        left: 0;
                    }
                    @include for-sp() {
                        top: 12px;
                        width: 10px;
                        height: 10px;
                    }
                }
            }
        }
    }
    .profile{
        .profile-tb{
            @include for-tb-sm(){
                tr{
                    display: grid;
                    grid-template-columns: 1fr;
                    th{
                        padding-bottom: 10px;
                    }
                    td{
                        padding-top: 0;
                    }
                }
            }
        }
    }
    .policy {
        padding-bottom: 100px;
        @include for-sp() {
            padding-bottom: 60px;
        }
        .policy-list {
            list-style: none;
            padding: 0;
            font-size: 2.4rem;
            line-height: 1.5;
            @include for-tb() {
                font-size: 2rem;
            }
            @include for-sp() {
                font-size: 1.5rem;
            }
            li {
                padding: 25px 0;
                margin-bottom: 15px;
                padding-left: 65px;
                position: relative;
                border: 2px solid #222;
                border-radius: 5px;
                background: #fff;
                @include for-tb() {
                    padding: 25px 10px 25px 65px;
                }
                @include for-sp() {
                    padding: 15px 10px 15px 40px;
                }
                &:nth-child(2n) {
                    .policy-number {
                        color: #0096e0;
                        background: -webkit-linear-gradient(-90deg, #6fb16c, #0096e0);
                        -webkit-background-clip: text;
                        -webkit-text-fill-color: transparent;
                    }
                }
            }
            .policy-number {
                font-family: Roboto;
                font-weight: 600;
                position: absolute;
                left: 0;
                top: 50%;
                transform: translateY(-50%);
                width: 65px;
                text-align: center;
                font-size: 6rem;
                line-height: 1;
                color: #e84091;
                background: -webkit-linear-gradient(-90deg, #e84091, #f1d32d);
                -webkit-background-clip: text;
                -webkit-text-fill-color: transparent;
                @include for-sp() {
                    font-size: 4rem;
                    width: 40px;
                }
            }
        }
    }
    .member{
        .member-list{
            gap: 70px 40px;
            &.flex {
                display: flex;
                justify-content: center;
                flex-wrap: wrap;
                margin-bottom: 30px;
                @include for-sp(){
                    flex-direction: column;
                    gap: 20px;
                    margin-bottom: 10px;
                }
                .member-box {
                    width: calc((100% - 80px)/3);
                    @include for-tb(){
                        width: calc((100% - 40px)/2);
                    }
                    @include for-sp(){
                        width: 100%;
                    }
                }
            }
            &.grid {
                display: grid;
                grid-template-columns: repeat(3, 1fr);
                @include for-tb(){
                    grid-template-columns: repeat(2, 1fr);
                }
                @include for-sp(){
                    grid-template-columns: repeat(1, 1fr);
                    gap: 20px;
                }
                .member-box{
                    display: grid;
                    grid-row: span 4;
                    grid-template-rows: subgrid;
                    gap: 12px;
                }
            }
            .member-box{
                text-align: center;
                .member-image{
                    margin-bottom: 20px;
                    img{
                        width: 250px;
                    }
                }
                .member-title{
                    margin-bottom: 15px;
                    line-height: 1.3;
                    font-size: 2.4rem;
                    font-weight: 600;
                    padding: 0;
                    &:before{
                        content: none;
                    }
                    @include for-sp(){
                        font-size: 1.8rem;
                    }
                    .member-post{
                        display: block;
                    }
                    .member-name{
                        font-size: 3.2rem;
                        @include for-sp(){
                            font-size: 2.4rem;
                        }
                    }
                }
                .member-director{
                    font-size: 1.55rem;
                    line-height: 1.5;
                    font-weight: 600;
                    margin-bottom: 24px;
                    @include for-sp(){
                        font-size: 1.6rem;
                        margin-bottom: 15px;
                    }
                }
                .member-text{
                    font-size: 1.4rem;
                    line-height: 1.5;
                    margin-bottom: 0;
                    @include for-sp(){
                        margin-bottom: 15px;
                        font-size: 1.6rem;
                    }
                }
                // .member-text-margin-top {
                //     margin-top: 71px;
                //     @include for-tb(){
                //         margin-top: 48px;
                //     }
                //     @include for-sp(){
                //         margin-top: 0;
                //     }
                // }
            }
            .sns-area{
                margin-bottom: 32px;
                .sns-area-wrap{
                    display: flex;
                    justify-content: center;
                    .sns-icon{
                        width: 70px;
                        @include for-tb(){
                            width: 40px;
                        }
                        img{
                            width: auto;
                            height: 50px;
                            @include for-tb(){
                                height: 30px;
                            }
                        }
                    }
                }
            }
        }
        .representative{
            @include for-tb(){
                justify-content: center;
            }
        }
    }
    .partner {
        background-color: $BGC_YELLOW;
        padding-top: 90px;
        padding-bottom: 120px;
        @include for-sp() {
            padding-top: 35px;
            padding-bottom: 35px;
        }
        .explan {
            text-align: center;
            font-size: 2rem;
            margin: 0 auto 60px;
            @include for-sp() {
                margin: 0 auto;
                font-size: 1.4rem;
            }
        }
        .partner-list {
            .partner-list-parallel {
                display: grid;
                grid-template-columns: 1fr 1fr 1fr;
                gap: 34px;
                @include for-pc() {
                    gap: 20px;
                }
                @include for-tb() {
                    grid-template-columns: 1fr 1fr;
                }
                @include for-sp() {
                    grid-template-columns: 1fr;
                }
                .partner-section {
                    .section-contens {
                        display: block;
                    }    
                }
            }
            .partner-section {
                margin-top: 30px;
                .section-contens {
                    display: grid;
                    grid-template-columns: 1fr 1fr 1fr;
                    gap: 34px;
                    @include for-pc() {
                        gap: 20px;
                    }
                    @include for-tb() {
                        grid-template-columns: 1fr 1fr;
                    }
                    @include for-sp() {
                        grid-template-columns: 1fr;
                    }
                }
            }
            .adjust {
                @include for-tb() {
                    display: none;
                }
            }
            h3,
            span {
                height: 24px;
                display: block;
                line-height: 1;
                font-weight: 600;
                margin-bottom: 30px;
                @include for-sp() {
                    margin-bottom: 10px;
                    height: auto;
                }
            }
            .partner-item {
                display: flex;
                flex-direction: column;
                background-color: $WHITE;
                border-radius: 30px;
                padding: 10px 26px 0;
                height: 325px;
                transition: all 0.3s;
                &:hover {
                    background-color: $BGC_BLUE;
                    text-decoration: none;
                    cursor: pointer;
                }
                @include for-tb() {
                    height: 300px;
                }
                @include for-tb-sm() {
                    height: 350px;
                }
                @include for-sp() {
                    height: auto;
                    padding-top: 0;
                    padding-bottom: 20px;
                }
                h4 {
                    text-align: center;
                    margin-bottom: 10px;
                    line-height: 1.3;
                    color: $FONT_COLOR;
                    font-size: 2rem;
                    margin-bottom: 20px;
                    font-weight: 600;
                    height: 65px;
                    @include for-tb-sm() {
                        height: 60px;
                    }
                    @include for-sp() {
                        height: auto;
                        margin-bottom: 10px;
                        line-height: 1;
                        font-size: 1.8rem;
                    }
                }
                img {
                    height: 125px;
                    display: block;
                    margin: 0 auto;
                    width: auto;
                    @include for-sp() {
                        height: 100px;
                    }
                }
                p {
                    text-align: center;
                    font-size: 1.6rem;
                    line-height: 1.5;
                    color: $FONT_COLOR;
                    @include for-tb() {
                        font-size: 1.5rem;
                    }
                }
                span {
                    display: block !important;
                    font-size: 1.6rem;
                    color: $FONT_COLOR;
                    font-weight: 500;
                    text-align: center;
                    line-height: 1.5;
                    margin-bottom: 0;
                    @include for-tb() {
                        font-size: 1.5rem;
                    }
                }
            }
            .second-pc {
                margin-top: 0;
                span {
                    display: none;
                }
            }
            .second-tb {
                @include for-tb() {
                    margin-top: 0;
                    span {
                        display: none;
                    }
                }
            }
            .second-sp {
                @include for-sp() {
                    margin-top: 0;
                    span {
                        display: none;
                    }
                }
            }
        }
    }
}
