@use "../global" as *;
//プライバシーポリシー
#privacy{
    .contents{
        ul{
            padding-left: 20px;
            list-style: disc;
            margin-top: 32px;
            @include for-sp(){
                line-height: 2;
            }
        }
    }
    h3{
        font-size: 2.1rem;
        font-weight: 600;
        margin-bottom: 16px;
        line-height: 1.3;
        @include for-sp(){
            font-size: 1.8rem;
            letter-spacing: 0.02em;
        }
    }
    .box{
        @include for-sp(){
            margin-bottom: 15px;
            h3{
                padding-top: 0;
                padding-bottom: 0;
            }
            p{
                margin-bottom: 0;
            }
        }
    }
}