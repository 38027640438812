@use "../global" as *;
//事例紹介
#case{
    .contents{
        background-image: url(../images/bg-case.png);
        background-size: 754px 750px;
        background-repeat: repeat;
        @include for-sp(){
            background-size: 377px 375px;
        }
        .block{
            .ashitane-link{
                width: 50%;
                display: block;
                margin: 0 auto;
                background: #EBF4D9;
                border-radius: 30px;
                padding: 15px 10px;
                transition: all .3s;
                &:hover{
                    opacity: 0.8;
                }
                @include for-tb-sm(){
                    width: 80%;
                }
                img{
                    width: 60%;
                    text-align: center;
                    margin: 0 auto;
                    display: block;
                    @include for-tb-sm(){
                        width: 75%;
                    }
                }              
            }
            .case-list {
                margin-top: 77px;
                display: grid;
                grid-template-columns: 1fr 1fr 1fr;
                margin: 77px auto 0;
                gap: 30px;
                @include for-tb() {
                    grid-template-columns: 1fr;
                    gap: 28px;
                    padding: 0 30px;
                }
                @include for-sp() {
                    margin: 30px auto 0;
                    padding: 0;
                }
                .case-item {
                    position: relative;
                    transition: all .3s;
                    padding-bottom: 30px;
                    @include for-tb(){
                        padding-bottom: 20px;
                    }
                    &:hover{
                        cursor: pointer;
                        cursor: hand;
                        opacity: 0.7;
                        text-decoration: none;
                    }
                    &:nth-child(2n) {
                        @include for-tb() {
                            .item-head {
                                flex-direction: row-reverse;
                                right: 0;
                                h3 {
                                    margin-left: 0;
                                    margin-right: 13px;
                                }
                            }
                            .item-text{
                                text-align: right;
                            }
                            .item-date{
                                right: 30px;
                            }
                        }
                        @include for-sp(){
                            .item-text{
                                text-align: left;
                            }
                            .item-date{
                                right: 0;
                                left: 0;
                            }
                        }
                    }
                    .item-head {
                        display: flex;
                        align-items: center;
                        position: absolute;
                        top: 0;
                        left: -10px;
                        span {
                            background: linear-gradient(180deg, rgba(255, 177, 177, 1), rgba(255, 223, 185, 1));
                            width: 100px;
                            height: 100px;
                            line-height: 100px;
                            text-align: center;
                            display: inline-block;
                            border-radius: 50%;
                            font-size: 4rem;
                            color: $WHITE;
                            @include for-tb(){
                                width: 80px;
                                height: 80px;
                                line-height: 80px;
                                font-size: 2rem;
                            }
                            @include for-tb() {
                                width: 73px;
                                height: 73px;
                                font-size: 2.8rem;
                                line-height: 75px;
                            }
                        }
                        h3 {
                            margin-left: 13px;
                            font-weight: 600;
                            font-size: 2.2rem;
                            color: $FONT_COLOR;
                            padding-left: 0;
                            line-height: 1.3;
                            &:before{
                                content: none;
                            }
                            @include for-tb() {
                                font-size: 2rem;
                            }
                        }
                    }
                    .item-text {
                        font-size: 2.2rem;
                        line-height: 1.5;
                        color: $FONT_COLOR;
                        padding-top: 111px;
                        display: block;
                        font-weight: 600;
                        letter-spacing: 0.2em;
                        text-decoration: none;
                        //height: 200px;
                        text-align: left;
                        position: inherit;
                        margin-bottom: 0;
                        &:after{
                            content: none;
                        }
                        @include for-tb() {
                            font-size: 1.8rem;
                            padding-left: 30px;
                            padding-top: 80px;
                            padding-right: 30px;
                            letter-spacing: 0.1em;
                            //height: 230px;
                        }
                        @include for-sp(){
                            //height: auto;
                        }
                    }
                    .item-date {
                        display: block;
                        line-height: 1;
                        font-size: 1.4rem;
                        color: $GRAY;
                        margin-top: 16px;
                        position: absolute;
                        bottom: 0;
                        @include for-tb() {
                            font-size: 1.2rem;
                            padding-left: 30px;
                            margin-top: 8px;
                        }
                    }
                }
            }
            .link-area{
                width: 700px;
                margin: 0 auto;
                @include for-tb-sm(){
                    width: 100%;
                }
                a{
                    transition: all .3s;
                    &:hover{
                        opacity: 0.7;
                    }
                }
            }
        }
    }
}
//事例詳細
#casedetail{
    .contents{
        h2{
            text-align: left;
            &:after{
                width: 100%;
            }
        }
        h3{
            font-size: 2.6rem;
            @include h3style();
            @include for-tb(){
                font-size: 2.3rem;
            }
            @include for-sp(){
                font-size: 2.3rem;
            }
        }
        h4{
            font-size: 2rem;
            margin-bottom: 10px;
            font-weight: 600;
            @include for-tb(){
                font-size: 1.8rem;
            }
            @include for-sp(){
                font-size: 1.8rem;
            }
        }
        p{
            margin-bottom: 50px;
            @include for-tb(){
                margin-bottom: 40px;
            }
            @include for-sp(){
                margin-bottom: 32px;
            }
        }
        .genogram-img{
            background-color: #FCE8E8;
            img{
                width: 600px;
                display: block;
                margin: auto;
                padding: 50px;
                @include for-sp(){
                    width: 100%;
                    padding: 20px;
                }
            }
        }
        .interview-area{
            h3{
                margin-bottom: 80px;
            }
            h4,p{
                position: relative;
                border-radius: 6px;
                border: 2px solid #cccccc;
                //box-shadow: 1px 1px 5px #aaa;
                z-index: 1;
                padding: 25px;
                width: 90%;
                @include for-tb(){
                    width: 85%;
                }
                @include for-tb-sm(){
                    width: 80%;
                }
                @include for-sp(){
                    width: 100%;
                    padding: 18px;
                }
            }
            h4{
                border: none;
                padding: 15px 25px 55px 0;
                margin-left: 100px;
                line-height: 1.5;
                @include for-sp(){
                    margin-bottom: 65px;
                    margin-left: 0;
                    padding: 0 0 18px 0;
                }
                /*&:before{
                    position: absolute;
                    content: "";
                    top: 35px;
                    width: 10px;
                    height: 10px;
                    border-right: 2px solid #999;
                    border-bottom: 2px solid #999;
                    background-color: #fff;
                    z-index: 2;
                    left: -7px;
                    transform: rotate(135deg);
                    @include for-sp(){
                        top: -7px;
                        left: 30px;
                        transform: rotate(225deg);
                    }
                }*/
                &:after{
                    content: "";
                    background-image: url("../images/interview_1.png");
                    width: 90px;
                    height: 90px;
                    position: absolute;
                    top: -15px;
                    left: -110px;
                    background-size: cover;
                    //border: 3px solid #fff;
                    @include for-sp(){
                        width: 55px;
                        height: 55px;
                        left: 0;
                        top: -60px;
                    }
                }
            }
            p{
                margin-right: 100px;
                margin-bottom: 70px;
                @include for-sp(){
                    margin-right: 0;
                    margin-bottom: 100px;
                }
                &:before{
                    position: absolute;
                    content: "";
                    top: 35px;
                    width: 15px;
                    height: 15px;
                    border-right: 2px solid #cccccc;
                    border-bottom: 2px solid #cccccc;
                    background-color: #fff;
                    z-index: 2;
                    right: -9px;
                    transform: rotate(-45deg);
                    @include for-sp(){
                        top: -9px;
                        right: 22px;
                        transform: rotate(225deg);
                    }
                }
                &:after{
                    content: "";
                    background-image: url("../images/interview_2.png");
                    width: 90px;
                    height: 90px;
                    position: absolute;
                    top: 0;
                    right: -110px;
                    background-size: cover;
                    border: 3px solid #fff;
                    @include for-sp(){
                        width: 55px;
                        height: 55px;
                        right: 0;
                        top: -70px;
                    }
                }
                &:last-child{
                    @include for-sp(){
                        margin-bottom: 30px;
                    }
                }
            }
        }
        .back-btn-area{
            display: flex;
            justify-content: center;
            a{
                background: $BTN_BLUE;
                padding: 10px 30px;
                border-radius: 35px;
                color: white;
                //font-weight: 800;
                transition: all .3s;
                letter-spacing: .2em;
                &:hover{
                    opacity: .8;
                    text-decoration: none;
                    background-color: $FONT_BLUE;
                }
            }
        }
    }
}