@use "../global" as *;
//サービス
#service {
    .contents {
        .contents-block {
            h2 {
                padding-top: 50px;
                @include for-sp() {
                    padding-top: 20px;
                }
            }
        }
        .policy-list {
            list-style: none;
            padding: 0;
            font-size: 2.4rem;
            line-height: 1.5;
            @include for-tb() {
                font-size: 2.2rem;
            }
            @include for-sp() {
                font-size: 2rem;
            }
            li {
                background: #fff;
                padding-top: 45px;
                .list-wrap{
                    padding: 25px 0;
                    padding-left: 65px;
                    position: relative;
                    border: 2px solid #222;
                    border-radius: 5px;
                    height: 130px;
                    display: flex;
                    align-items: center;
                    @include for-tb() {
                        padding: 25px 10px 25px 65px;
                    }
                    @include for-sp() {
                        padding: 15px 10px 15px 40px;
                        height: 145px;
                    }
                }
                &:nth-child(1){
                    padding-top: 0;
                }
                &:nth-child(2n) {
                    .policy-number {
                        color: #0096e0;
                        background: -webkit-linear-gradient(-90deg, #6fb16c, #0096e0);
                        -webkit-background-clip: text;
                        -webkit-text-fill-color: transparent;
                    }
                }
            }
            .policy-number {
                font-family: Roboto;
                font-weight: 600;
                position: absolute;
                left: 0;
                top: 50%;
                transform: translateY(-50%);
                width: 65px;
                text-align: center;
                font-size: 6rem;
                line-height: 1;
                color: #e84091;
                background: -webkit-linear-gradient(-90deg, #e84091, #f1d32d);
                -webkit-background-clip: text;
                -webkit-text-fill-color: transparent;
                @include for-sp() {
                    font-size: 4rem;
                    width: 40px;
                }
            }
            .txt{
                display: block;
                font-size: 1.8rem;
                padding-top: 15px;
                line-height: 1.7;
                @include for-sp(){
                    font-size: 1.6rem;
                }
            }
        }
        .logo-area{
            width: 486px;
            height: 177px;
            text-align: center;
            margin: 25px auto 0;
            @include for-tb-sm(){
                width: 260px;
                height: 100%;
                margin: 10px auto 0;
            }
        }
        .contents-flex{
            display: flex;
            gap: 50px;
            margin: 80px auto 0;
            width: 1000px;
            padding: 20px;
            @media (max-width: 1000px) {
                width: auto;
            }
            @include for-tb-sm(){
                display: block;
                padding-left: 0;
                padding-right: 0;
                margin-top: 0;
                padding-top: 30px;
                padding-bottom: 30px;
            }
            .text-area{
                width: 540px;
                text-align: left;
                line-height: 2;
                @include for-tb-sm(){
                    width: 100%;
                }
                .txt-1{
                    
                }
                .txt-2{
                    //padding-top: 10px;
                    .ol-list{
                        li{
                            border: none;
                            font-size: 2rem;
                            font-weight: 700;
                            padding-top: 10px;
                            padding-bottom: 10px;
                            margin-bottom: 0;
                        }
                    }
                    .txt-bg{
                        display: block;
                        font-size: 2.2rem;
                    }
                }
            }
            .img-area{
                width: 380px;
                @include for-tb-sm(){
                    display: none;
                }
                img{
                    border-radius: 25px;
                }
            }
        }
        .link-area{
            width: 700px;
            margin: 0 auto;
            @include for-tb-sm(){
                width: 100%;
            }
            a{
                display: block;
                transition: all .3s;
                &:hover{
                    opacity: 0.7;
                }
                &:nth-child(n+2) {
                    margin-top: 20px;
                }
            }
        }
    }
    .description {
        h3 {
            &:not(:first-of-type) {
                margin-top: 150px;
                @include for-tb-sm() {
                    margin-top: 70px;
                }
            }
        }
    }
    h3 {
        @include h3style();
        margin-bottom: 40px;
        margin-top: 70px;
        @include for-sp() {
            margin-bottom: 30px;
        }
    }
    h4 {
        @include h4style();
    }
    .overview-img {
        padding-top: 58px;
        @include for-tb-sm() {
            padding-top: 38px;
        }
        @include for-sp() {
            padding-top: 18px;
        }
    }
    .box {
        padding: 15px 15px 20px;
        ul {
            list-style: circle;
            padding-left: 30px;
            line-height: 1.7;
            margin-bottom: 0;
        }
        p {
            margin-bottom: 0;
        }
    }
    table {
        td {
            &:first-child {
                font-weight: 600;
            }
        }
    }
    .webinar-price{
        tr{
            &:nth-of-type(1){
                th:last-of-type{
                    line-height: 2.5;
                }
            }
        }
        th{
            width: 30%;
            @include for-sp(){
                width: 65%;
            }
        }
    }
    .community{
        text-align: center;
        .logo-area{
            width: 486px;
            height: 177px;
            text-align: center;
            display: inline-block;
            margin-top: 25px;
            @include for-tb-sm(){
                width: 260px;
                height: 100%;
                margin-top: 10px;
            }
        }
        .contents-flex{
            display: flex;
            gap: 50px;
            margin: 80px auto 0;
            width: 1000px;
            padding: 20px;
            @media (max-width: 1000px) {
                width: auto;
            }
            @include for-tb-sm(){
                display: block;
                padding-left: 0;
                padding-right: 0;
                margin-top: 0;
                padding-top: 30px;
                padding-bottom: 30px;
            }
            .text-area{
                width: 540px;
                text-align: left;
                line-height: 2;
                @include for-tb-sm(){
                    width: 100%;
                }
                .txt-1{
                    
                }
                .txt-2{
                    //padding-top: 10px;
                    .ol-list{
                        li{
                            border: none;
                            font-size: 1.6rem;
                            font-weight: 700;
                            padding-top: 10px;
                            padding-bottom: 10px;
                            margin-bottom: 0;
                        }
                    }
                    .txt-bg{
                        display: block;
                        font-size: 2.2rem;
                    }
                }
            }
            .img-area{
                width: 380px;
                @include for-tb-sm(){
                    display: none;
                }
                img{
                    border-radius: 25px;
                }
            }
        }
        .link-area{
            display: inline-block;
            width: 700px;
            margin: 0 auto;
            @include for-tb-sm(){
                width: 100%;
            }
            a{
                display: block;
                transition: all .3s;
                &:hover{
                    opacity: 0.7;
                }
                &:nth-child(n+2) {
                    margin-top: 20px;
                }
            }
        }        
    }
    
}
//障害者のご家族の方へ
.bn-guardianship {
    //親なきあと後見・見守りサービス付任意後見契約ページ
    .key-wrapper {
        padding: 50px 60px !important;
        @include for-sp() {
            padding: 28px 20px !important;
        }
        .key-title {
            h1 {
                @include for-sp() {
                    &:before {
                        left: -18px !important;
                    }
                    &:after {
                        right: -18px !important;
                    }
                }
            }
        }
    }
    thead th {
        padding: 15px !important;
    }
    .price {
        @include for-sp() {
            tr,
            th,
            td {
                display: block;
                width: 100% !important;
            }
            th,
            td {
                padding-left: 10px;
                &:not(:first-child) {
                    padding-top: 0;
                }
            }
        }
    }
    .monitoring {
        thead {
            th {
                text-align: center;
                &:first-child {
                    @include for-sp() {
                        display: none !important;
                    }
                }
            }
            @include for-sp() {
                display: none;
            }
        }
        tbody {
            @include for-sp() {
                tr,
                th,
                td {
                    display: block;
                    width: 100% !important;
                }
                th,
                td {
                    padding-left: 10px;
                    &:not(:first-child) {
                        padding-top: 0;
                    }
                }
            }
        }
    }
    .reward {
        th,
        td {
            text-align: center;
        }
        th {
            width: 55%;
            @include for-sp() {
                padding-left: 10px;
                text-align: left;
                width: 65%;
            }
        }
        td {
            @include for-sp() {
                text-align: right;
                padding-right: 10px;
                white-space: nowrap;
            }
        }
        thead {
            th {
                @include for-sp() {
                    text-align: center;
                }
            }
        }
    }
}
// 相談支援機関向け「よりそいサポート」
.bn-yorisoisupport {
    .yorisoi-balloon-wrap {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 32px;
        margin-bottom: 32px;
        img {
            max-width: 600px;
        }
    }
    .ol-list {
        li {
            border: none;
            background-color: #f4ffe6;
            font-weight: 600;
        }
    }
    .price-box {
        padding: 30px 20px;
        border-radius: 15px;
        background-color: #fbe3e2;
        text-align: center;
        line-height: 1.7;
        @include for-sp(){
            padding: 20px;
        }
    }
    .price {
        color: #e03e2d;
        font-size: 28px;
        @include for-sp(){
            font-size: 21px;
        }
    }
}