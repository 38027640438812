@use 'mixin' as m;
$FONT_SIZE: 1.8rem;
$FONT_FAMILY: YuGothic, "游ゴシック", 'Chikushimaru Gothic', 'Prompt', sans-serif;
$FONT_WEIGHT: 500;
$FONT_STYLE: normal;
$LINE_HEIGHT: 2.5;
$LETTER_SPACING: 0.05em;
  
.font-bg {
    font-size: 4.1rem;
    @include m.for-tb-sm(){
      font-size: 3.4rem;
    }
    @include m.for-sp(){
      font-size: 2.7rem;
    }
}

.font-sm {
    font-size: 1.2rem;
    @include m.for-tb-sm(){
      font-size: 1rem;
    }
    @include m.for-sp(){
      font-size: 0.8rem;
    }
}

.font-light {
    font-weight: 300;
}

.font-regular {
    font-weight: 400;
}

.font-medium {
    font-weight: 500;
}

.font-bold {
    font-weight: 600;
}
.font-roboto{
  font-family: Roboto;
}
.font-marugothic{
  //font-family: 'Zen Maru Gothic', sans-serif;
  font-family: "筑紫A丸ゴシック", "Chikushimaru Gothic", fot-tsukuardgothic-std, sans-serif;
}
.font-prompt{
  font-family: 'Prompt', sans-serif;
}