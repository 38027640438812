@use "../global" as *;
*,
:before,
:after {
    box-sizing: border-box;
    word-break: break-all;
}

html {
    font-size: 62.5%;
}

body {
    font-size: $FONT_SIZE;
    font-family: $FONT_FAMILY;
    color: $FONT_COLOR;
    line-height: $LINE_HEIGHT;
    letter-spacing: $LETTER_SPACING;
    font-style: $FONT_STYLE;
    -webkit-font-feature-settings: "palt";
    font-feature-settings: "palt";
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    -webkit-text-size-adjust: 100%;
    margin: 0;
    padding: 0;
    overflow-x: hidden;
}

body.is-fixed {
    width: 100%;
    height: 100%;
    overflow: hidden;
}

img {
    vertical-align: middle;
    width: 100%;
    max-width: 100%;
    height: auto;
    aspect-ratio: auto 16 / 9;
}

figure {
    margin: 0 auto;
    overflow: hidden;
}

strong {
    font-weight: bold;
}

#wrapper {
    width: 100%;
    margin: 0 auto;
}
h1 {
    font-weight: 600;
}
h2 {
    font-size: 3.5rem;
    font-weight: 600;
    line-height: 2.4;
    letter-spacing: 0.1em;
    @include for-tb() {
        font-size: 3rem;
    }
    @include for-sp() {
        font-size: 2.7rem;
    }
}
h3 {
    font-size: 2.7rem;
    font-weight: 600;
    line-height: 2.4;
    letter-spacing: 0.1em;
    @include for-tb() {
        font-size: 2.4rem;
    }
    @include for-sp() {
        font-size: 2.1rem;
    }
}
h4,
p,
ul,
ol,
dt,
dd {
    font-size: 2rem;
    font-weight: 500;
    @include for-tb() {
        font-size: 1.9rem;
    }
    @include for-sp() {
        font-size: 1.8rem;
    }
}
dt{
    font-weight: 600;
}
table {
    width: 100%;
    font-size: 1.8rem;
    line-height: 1.5;
    border-collapse: collapse;
    @include for-sp() {
        font-size: 1.8rem;
    }
    thead{
        background-color: #A9D3E0;
        th{
            text-align: center;
        }
    }
    tbody {
        tr {
            border-bottom: solid 1px $FONT_COLOR;
            th {
                font-weight: 600;
                padding: 20px 0 20px 10px;
                width: 30%;
                @include for-sp() {
                    padding: 20px 0;
                }
            }
            td {
                padding: 20px 10px 20px 0;
                @include for-sp() {
                    padding: 20px 0;
                }
            }
        }
    }
}

input[type="text"],
input[type="number"],
input[type="password"],
input[type="url"],
input[type="email"],
textarea {
    appearance: none;
    width: 100%;
    border: 2px solid #6c716b;
    padding: 5px 10px;
    border-radius: 5px;
    font-size: 2rem;
    background: 0 0;
    @include for-sp() {
        font-size: 1.8rem;
    }
}

input[type="button"],
input[type="submit"],
button {
    font-family: inherit;
    margin: 0;
    cursor: pointer;
    appearance: none;
    border: none;
    margin: 0;
    cursor: pointer;
    transition: all 0.5s 0s ease;
    &:hover {
        opacity: 0.8;
    }
}

input[type="text"]:focus,
input[type="number"]:focus,
input[type="password"]:focus,
input[type="url"]:focus,
input[type="email"]:focus,
textarea:focus {
    background: $BGC_RIGHT_GRAY;
}

input[type="reset"] {
    font-family: inherit;
    border: none;
    color: $WHITE;
    font-weight: 700;
    font-size: 1.7rem;
    cursor: pointer;
    letter-spacing: inherit;
    -webkit-appearance: none;
    &:hover {
        text-decoration: underline;
    }
    @include for-tb() {
        font-size: 1.5rem;
    }
    @include for-sp() {
        font-size: 1rem;
        padding-left: 0px;
        padding-right: 0px;
        letter-spacing: 0.05em;
    }
}

a {
    color: $ANK_BLUE;
    font-weight: 400;
    font-size: 2.1rem;
    &:hover {
        text-decoration: underline;
        cursor: pointer;
    }
    @include for-sp() {
        font-size: 1.8rem;
    }
}
.spbr {
    display: none;
    @include for-sp() {
        display: block;
        line-height: 1.2;
    }
}

.tbbr {
    display: none;
    @include for-tb() {
        display: block;
        line-height: 1.2;
    }
}

.pcbr {
    display: block;
    line-height: 1.2;
    @include for-tb() {
        display: none;
    }
}
.br-over-tb {
    display: block;
    line-height: 1.2;
    @include for-tb-sm() {
        display: none;
    }
}
.br-over-sp {
    display: block;
    line-height: 1.2;
    @include for-sp() {
        display: none;
    }
}
.eraze-br-sp {
    display: block;
    @include for-sp() {
        display: none;
    }
}
.br-tb {
    display: initial;
    @include for-tb() {
        display: block;
        line-height: 1;
    }
}
.br-sp {
    display: initial;
    @include for-sp() {
        display: block;
        line-height: 1;
        padding-bottom: 5px;
    }
}
.center {
    text-align: center;
}
.text-left {
    text-align: left;
}
.text-right {
    text-align: right;
}
.br {
    line-height: 1.2;
    &:before {
        content: "\A";
        white-space: pre;
    }
}
hr {
    margin: 20px auto;
    color: $GRAY;
}
.red {
    color: $RED;
}
.emphasis {
    font-weight: 700;
    color: $RED;
}
.strong {
    font-weight: 700;
    font-size: 1.6rem;
}
.s {
    font-size: 1.7rem;
    @include for-sp() {
        font-size: 1.5rem;
    }
}
.br-tbonly {
    display: none;
    @include for-tb() {
        display: block;
    }
    @include for-sp() {
        display: none;
    }
}
.box {
    background-color: #f1f1f1;
    padding: 24px;
    margin-top: 32px;
    margin-bottom: 32px;
}
.blue{
    color: #7ACCE5;
}
.sphide {
    display: block;
    @include for-sp() {
        display: none;
    }
}
.spshow {
    display: none;
    @include for-sp() {
        display: block;
    }
}
.spmin {
    @include for-sp() {
        font-size: 1rem;
        display: block;
    }
}