@use "../global" as *;
.header-background-color {
    //background-color: rgba(250, 223, 224, 0.9);
    background-color: rgba(255, 255, 255, 0.9);
}
header {
    z-index: 1;
    //position: relative;
    position: fixed;
    z-index: 998;
    top: 0;
    width: 100%;
    height: 100px;
    background-color: transparent; /* 初期状態では透明に設定 */
    transition: background-color 0.3s ease; /* 背景色の変化を滑らかにするアニメーション */
    @include for-sp(){
        height: 90px;
    }
    @include for-sp(){
        height: 75px;
    }
    .wrapper {
        display: flex;
        justify-content: space-between;
        padding: 0 30px 0 34px;
        align-items: center;
        @include for-sp(){
            padding: 5px 15px 0 15px;
        }
        .logo {
            line-height: 5;
            width: 285px;
            @include for-sp(){
                width: 100%;
                line-height: 3;
            }
            img {
                width: 100%;
                height: 60px;
                @include for-sp(){
                    width: 60%;
                    height: auto;
                }
            }
        }
        #menu {
            #menu-btn {
                @include for-pc() {
                    display: block;
                    width: 60px;
                    height: 60px;
                    position: fixed;
                    right: 10px;
                    top: 15px;
                    cursor: pointer;
                    z-index: 999;
                    overflow: hidden;
                }
                @include for-sp(){
                    top: 10px;
                }
                .menu-line {
                    @include for-pc() {
                        display: block;
                        width: 32px;
                        height: 2px;
                        border-radius: 1px;
                        background: #4f4f4f;
                        position: absolute;
                        left: calc(50% - 16px);
                        &:nth-child(1) {
                            top: 15px;
                            transition: top 0.1s cubic-bezier(0.455, 0.03, 0.515, 0.955) 0.2s,
                                transform 0.2s cubic-bezier(0.455, 0.03, 0.515, 0.955) 50ms,
                                -webkit-transform 0.2s cubic-bezier(0.455, 0.03, 0.515, 0.955) 50ms;
                            width: 40px;
                        }
                        &:nth-child(2) {
                            top: 24px;
                            transition: opacity 0.1s cubic-bezier(0.455, 0.03, 0.515, 0.955) 0s;
                            width: 27px;
                        }
                        &:nth-child(3) {
                            top: 33px;
                            transition: top 0.1s cubic-bezier(0.455, 0.03, 0.515, 0.955) 0.2s,
                                transform 0.2s cubic-bezier(0.455, 0.03, 0.515, 0.955) 50ms,
                                -webkit-transform 0.2s cubic-bezier(0.455, 0.03, 0.515, 0.955) 50ms;
                            width: 13px;
                        }
                    }
                }
            }
            .navi-wrap {
                @include for-pc() {
                    display: block;
                    visibility: hidden;
                    opacity: 0;
                    transition: all 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94);
                    width: 100%;
                    height: 100%;
                    padding: 80px 0;
                    background: rgba(255, 255, 255, 0.85);
                    backdrop-filter: blur(3px);
                    overflow: auto;
                    position: fixed;
                    z-index: 998;
                    top: 0;
                    left: 0;
                }
                @include for-tb(){
                    display: flex;
                    align-items: center;
                }
                @include for-sp(){
                    padding: 25px 0;
                }
                .gnavi {
                    @include for-pc() {
                        position: relative;
                        z-index: 1;
                        padding: 0 8%;
                        width: 100%;
                        text-align: center;
                        font-size: 2.1rem;
                        display: grid;
                        grid-auto-columns: 1fr;
                    }
                    .gnavi-list {
                        display: flex;
                        @include for-pc() {
                            display: inline-block;
                            text-align: left;
                            margin: 0 auto;
                            order: 1;
                        }
                        .information,
                        .handicap,
                        .case,
                        .aboutus,
                        .contact
                         {
                            margin-left: 30px;
                            @media (max-width: 1365px) {
                                margin-left: 20px;
                            }
                            @include for-pc() {
                                margin-left: 0;
                                margin-top: 15px;
                            }
                            @include for-sp() {
                                margin-top: 10px;
                            }
                        }
                        a {
                            position: relative;
                            font-size: 2rem;
                            font-weight: 600;
                            display: inline-block;
                            line-height: 1.5;
                            &:after {
                                position: absolute;
                                left: 0;
                                content: "";
                                width: 100%;
                                height: 1px;
                                background: $FONT_COLOR;
                                bottom: -3px;
                                transform: scale(0, 1);
                                transform-origin: center top; /*変形（アンダーラインの伸長）の原点がaタグ（各メニュー）の右端*/
                                transition: transform 0.3s; /*変形の時間*/
                            }
                            &:hover {
                                &:after {
                                    transform: scale(1, 1); /*ホバー後、x軸方向に1（相対値）伸長*/
                                }
                            }
                        }
                        .active {
                            a {
                                border-bottom: solid 1px $FONT_COLOR;
                                padding-bottom: 2px;
                                &:after {
                                    content: none;
                                }
                            }
                        }
                    }
                    .sns-hamburger{
                        display: none;
                        order: 3;
                        margin-top: 60px;
                        @include for-pc(){
                            display: block;
                        }
                        @include for-sp(){
                            margin-top: 50px;
                        }
                        p{
                            font-size: 2.5rem;
                            @include for-sp(){
                                font-size: 1.6rem;
                            }
                        }
                        .sns-list{
                            display: flex;
                            justify-content: space-evenly;
                            align-items: center;
                        }
                        img{
                            width: 65px;
                            @include for-sp(){
                                //width: 85%;
                                width: 50px;
                            }
                        }
                    }
                    a {
                        color: $FONT_COLOR;
                        font-weight: 500;
                        text-decoration: none;
                    }
                }
            }
        }
    }
    #menu.is-open {
        .navi-wrap {
            visibility: visible;
            opacity: 1;
        }
        #menu-btn {
            .menu-line {
                &:nth-child(1) {
                    transform: rotate(-135deg);
                    top: 29px;
                    width: 33px;
                }
                &:nth-child(2) {
                    opacity: 0;
                }
                &:nth-child(3) {
                    transform: rotate(135deg);
                    top: 29px;
                    width: 33px;
                }
            }
        }
    }
}
