@use "../global" as *;
//サイトマップ
#sitemap{
    .contents{
        ul{
            list-style: circle;
            padding-left: 30px;
            a{
                color: $FONT_COLOR;
                line-height: 1;
                &:hover{
                    color: $FONT_BLUE;
                }
            }
            li{
                @include for-sp(){
                    margin-top: 10px;
                }
            }
        }
        .second{
            list-style: disc;
            margin-bottom: 0;
            @include for-sp(){
                margin-top: 7px;
            }
        }
    }
}