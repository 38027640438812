@use "../global" as *;
//お問い合わせ
#contact{
    .contact-lead{
        text-align: center;
        margin-bottom: 50px;
        @include for-sp(){
            margin-bottom: 20px;
        }
    }
    .contact-tb{
        margin-bottom: 60px;
        @include for-sp(){
            margin-bottom: 30px;
        }
        tr{
            @include for-tb(){
                font-size: 1.6rem;
            }
            @include for-sp(){
                padding: 30px 0;
                border-bottom: 2px solid #f1f1f1;
                display: grid;
            }
        }
        td,th{
            border-bottom: 2px solid #f1f1f1;
            padding: 40px 0;
            vertical-align: top;
        }
        th{
            width: 280px;
            padding-top: 45px;
            padding-right: 30px;
            @include for-tb(){
                width: 200px;
                padding-right: 20px;
            }
            @include for-sp(){
                width: 100%;
                border: none;
                padding: 0;
                margin-bottom: 15px;
                font-size: 1.6rem;
            }
            &.two-lines {
                padding-top: 35px;
                padding-bottom: 35px;
                @include for-sp(){
                    padding-top: 0;
                    padding-bottom: 0;
                }
                .require {
                    margin-top: -11px;
                    @include for-tb(){
                        margin-top: -8px;
                    }
                    @include for-sp(){
                        margin-top: 5px;
                    }
                }
            }
        }
        td{
            @include for-tb(){
                width: calc(100% - 200px);
            }
            @include for-sp(){
                width: 100%;
                padding: 0;
                border: none;
            }
        }
    }
    .contact-privacy{
        margin-bottom: 32px;
        text-align: center;
        @include for-sp(){
            text-align: left;
            font-size: 1.4rem;
        }
    }
    .btn-area{
        text-align: center;
    }
    .error-box{
        margin-bottom: 45px;
    }
    .btn-submit{
        @include for-sp(){
            width: 300px;
        }
    }
    .btn-confirm {
        margin-bottom: 30px;
        @include for-sp(){
            width: 300px;
            font-size: 1.8rem;
        }
    }
    .btn-back {
        background: #aaa;
        font-size: 1.6rem;
        height: 50px;
        border-radius: 25px;
        line-height: 50px;
        @include for-sp(){
            font-size: 1.4rem;
            width: 250px;
        }
    }
    .thanks-box{
        text-align: center;
        p{
            padding: 35px;
            @include for-sp(){
                padding: 35px 10px 0;
            }
        }
    }
}