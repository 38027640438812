@use "../global" as *;
//インフォメーション
#information {
    .info-detail-back{
        text-align: center;
        padding-top: 40px;
    }
    .info-list{
        li{
            margin-bottom: 25px;
            display: grid;
            grid-template-columns: 120px auto;
            gap: 15px;
            @include for-sp(){
                grid-template-columns: 1fr;
                gap: 0;
            }
        }
    }
    .info-date,.info-text{
        line-height: 1.5;
    }
    .info-date{
        font-weight: 600;
        font-size: 1.7rem;
        @include for-sp(){
            font-size: 1.4rem;
        }
    }
}
//記事詳細
.detail{
    .contents{
        h1{
            font-size: 3.7rem;
            letter-spacing: 0.2em;
            line-height: 1.5;
            margin-bottom: 50px;
            @include for-sp(){
                font-size: 2.5rem;
            }
        }
        h2{
            text-align: left;
            padding-top: 20px !important;
            &:after{
                width: 100%;
            }
        }
        h3{
            @include h3style();
            margin-bottom: 40px;
        }
        h4 {
            @include h4style();
        }
        h4,p{
            line-height: 1.5;
        }
        h4{
            margin-bottom: 20px;
            font-size: 2rem;
            @include for-tb(){
                font-size: 1.8rem;
            }
            @include for-sp(){
                font-size: 1.6rem;
            }
        }
        ul{
            list-style: disc;
        }
        ol{
            list-style: decimal;
        }
        ul,ol{
            padding-left: 30px;
            line-height: 1.5;
            li{
                padding-top: 15px;
                &:first-child{
                    padding-top: 0;
                }
            }
        }
    }
}
