@use 'color' as c;

$BREAK_PC:1179px;
$BREAK_TAB:959px;
$BREAK_TAB_SMALL:767px;
$BREAK_SP:559px;


@mixin for-pc() {
    @media (max-width: $BREAK_PC) {
        @content;
    }
}

@mixin for-tb() {
    @media (max-width: $BREAK_TAB) {
        @content;
    }
}

@mixin for-tb-sm() {
    @media (max-width: $BREAK_TAB_SMALL) {
        @content;
    }
}

@mixin for-sp() {
    @media (max-width: $BREAK_SP) {
        @content;
    }
}

@mixin base-width(){
    margin: 80px auto 0 auto;
    max-width: 1020px;
    padding: 0 10px;
    @include for-tb(){
        width: auto;
        padding: 0 23px;
    }
    @include for-sp(){
        margin-top: 40px;
        padding: 0 19px;
    }
}

@mixin h3style(){
    padding: 15px 10px;
    background: linear-gradient(to right, #7ACCE5, #BFE8FF, #FFEBEE);
    //border-left: 5px solid #7aa7e5;
    line-height: 1.3;
    @include for-sp(){
        padding: 10px;
    }
}

@mixin h4style(){
    //position: relative;
    //padding-left: 30px;
    margin-bottom: 10px;
    font-size: 2.1rem;
    font-weight: 600;
    @include for-tb(){
        font-size: 1.9rem;
    }
    @include for-sp(){
        font-size: 1.7rem;
        //padding-left: 25px;
    }
    /*&:before{
        content: "";
        position: absolute;
        left: 0;
        top: 50%;
        transform: translateY(-50%);
        width: 20px;
        height: 20px;
        background: conic-gradient(from 0deg, #ed679f, #ffd550, #19d979, #1ca6d0, #ed679f);
        border-radius: 50%;
        @include for-sp(){
            width: 15px;
            height: 15px;
        }
    }*/   
}