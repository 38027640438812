@use "../global" as *;
form{
    .require{
        color: #e84091;
        float: right;
        font-size: 1.4rem;
        display: inline-block;
        margin-top: 5px;
    }
    .input-box{
        display: inline-block;
    }
    #sei,#seikana,#mei,#meikana{
        width: 180px;
        @include for-tb(){
            width: 45%;
        }
    }
    #sei,#seikana{
        margin-right: 25px;
        @include for-sp(){
            margin-right: 10px;
        }
    }
    #ckemail{
        margin-top: 10px;
    }
    #tel{
        width: 240px;
    }
    #q1-other{
        display: inline-block;
        width: 60%;
        margin-left: 8px;
    }
    .q1-td{
        label{
            display: inline-block;
            padding: 8px 0;            
        }
    }
    #comment{
        height: 300px;
        @include for-tb(){
            height: 200px;
        }
    }
    .err {
        border-color: #e84091!important;
        input{
            border-color: #e84091!important;
        }
    }
    .formError {
        padding-top: 5px;
        display: block;
        .formErrorContent{
            padding: 5px 8px;
            width: auto;
            position: relative;
            color: #e84091;
            background: #ffe7f6;
            font-size: 1.4rem;
            line-height: 1.3;
        }
    }
    .errMsg {
        padding-bottom: 1px;
        display: block;
    }
    
}