@use 'color' as c;
@use 'mixin' as m;
$ease1:cubic-bezier(.445, .05, .55, .95);
$ease2:cubic-bezier(.455, .03, .515, .955);
$ease3:cubic-bezier(.645, .045, .355, 1);
$ease4:cubic-bezier(.72, 0, .14, 1);
$ease5:cubic-bezier(.25, .46, .45, .94);
$ease6:cubic-bezier(.8, .11, .65, .96);
$ease7:cubic-bezier(.25, .48, .63, .93);
$ease8:cubic-bezier(.92, .01, .74, .99);
$ease9:cubic-bezier(.1, .82, .1, 1);
$hover: 0.3s $ease7;
.fd-in {
    opacity: 0;
    transition: 3s;
}

.fd-done {
    opacity: 1;
}
.fade-up{
    transform: translateY(50px);
    opacity: 0;
}
.is-active{
    transition: 2.5s;
    transform: translateY(0);
    opacity: 1;        
}
@keyframes key-anime{
    0%{
        opacity: 0;
    }
    100%{
        opacity: 1;
    }
}