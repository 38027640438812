@use "../global" as *;
.btn {
    display: inline-block;
    font-size: 2.4rem;
    text-align: center;
    color: #fff;
    background: #f69516;
    height: 70px;
    border-radius: 35px;
    padding: 0 60px;
    line-height: 70px;
    font-weight: 700;
    transition: opacity .4s cubic-bezier(.25,.48,.63,.93);
    @include for-sp(){
        height: 50px;
        border-radius: 25px;
        padding: 0;
        line-height: 50px;
    }
}
.btn-submit{
    @include for-sp(){
        font-size: 1.8rem;
        width: 100%;
    }
}