@use "../global" as *;
//トップページ
#home {
    h1{
        font-size: initial;
    }
    .loading {
        width: 100vw;
        height: 100vh;
        //transition: all 1s;
        background-color: #fff;
        position: fixed;
        top: 0;
        left: 0;
        z-index: 9999;
        opacity: 1;
        visibility: visible;
    }
    .loading.is-active {
        opacity: 0;
        visibility: hidden;
        transition: opacity 4s, visibility 4s;
        //transition: all 5s;
    }
    .loading-animation {
        width: 100vw;
        height: 100vh;
        //transition: all 1s;
        //background-color: #20a3ea;
        background-color: #fff;
        z-index: 9999;
        display: flex;
        align-items: center;
        justify-content: center;
        opacity: 0;
        visibility: hidden;
        transition: opacity 3s, visibility 3s;
        img{
            @media (max-width: 1365px) {
                width: 90%;
                height: auto;
                padding: 0 15px 120px;
                margin: 0 auto;
                display: block;
            }
        }
    }
    .loading-animation.is-active {
        opacity: 1;
        visibility: visible;
        transition: opacity 3s, visibility 3s;
        //transition: all 5s;
    }
    .head-wrap {
        position: relative;
        height: 600px;
        background: url('../images/key_img.webp');
        background-size: cover;
        background-repeat: no-repeat;
        @include for-tb(){
            height: 550px;
        }
        @include for-tb-sm() {
            height: auto;
            padding-bottom: 50px;
        }
    }
    .head-img {
        position: absolute;
        mix-blend-mode: overlay;
    }
    .key {
        padding-top: 100px;
        .key-wrapper {
            padding: 55px 0 0 200px;
            animation: key-anime 2s cubic-bezier(.455,.03,.515,.955) .2s 1 normal both;
            @include for-pc(){
                padding: 45px 0 0 170px;
            }
            @include for-tb(){
                padding: 15px 0 0 100px;
            }
            @include for-tb-sm(){
                padding: 30px 0 0 100px;
            }
            @include for-sp() {
                padding: 15px 20px 0 20px;
            }
            .key-title {
                h2 {
                    font-size: 5.2rem;
                    line-height: 1.7;
                    letter-spacing: 0.1em;
                    @include for-tb-sm() {
                        font-size: 3.7rem;
                    }
                    @include for-sp(){
                        font-size: 3rem;
                    }
                }
            }
            .key-text {
                margin-top: 35px;
                @include for-sp() {
                    margin-top: 55px;
                }
                @include for-sp() {
                    width: 100%;
                    margin-top: 20px;
                }
                p {
                    //font-size: 1.8rem;
                    font-size: 2.4rem;
                    //font-weight: 700;
                    line-height: 2.2;
                    letter-spacing: 0.05em;
                    @include for-tb-sm() {
                        font-size: 2rem;
                    }
                    @include for-sp() {
                        font-size: 1.7rem;
                        letter-spacing: 0.18em;
                        line-height: 2;
                    }
                }
            }
        }
    }
    .sns {
        position: absolute;
        bottom: 0;
        left: 0;
        display: grid;
        grid-template-columns: 1fr;
        justify-items: center;
        background-color: #fff;
        border-radius: 0 10px 0 0;
        padding: 13px;
        @include for-pc() {
            display: none;
        }
        p {
            font-size: 1.1rem;
            letter-spacing: 0.2em;
            line-height: 1;
        }
        .sns-list {
            width: 26px;
            margin-top: 20px;
        }
    }
    .title-box {
        h2 {
            .bgimg {
                font-size: 2.4rem;
                letter-spacing: 0.2em;
                line-height: 1;
                display: block;
                color: $FONT_RIGHT_BLUE;
                position: relative;
                margin: 0 auto;
                z-index: 0;
                @include for-sp() {
                    font-size: 1.7rem;
                }
                &:after {
                    content: "";
                    position: absolute;
                    top: -20px;
                    right: -20px;
                    background-image: url("../images/titlepic-pink.png");
                    width: 35px;
                    height: 35px;
                    background-size: cover;
                    z-index: -1;
                    @include for-sp() {
                        top: -10px;
                        right: 12px;
                        width: 21px;
                        height: 21px;
                    }
                }
            }
            .spbg {
                &:after {
                    content: none;
                }
                @include for-sp() {
                    font-size: 2.4rem;
                    color: $FONT_COLOR;
                }
            }
            font-size: 3.5rem;
            letter-spacing: 0.3em;
            line-height: 2.4;
            @include for-tb() {
                font-size: 2.8rem;
            }
            @include for-sp() {
                font-size: 2.5rem;
            }
            span {
                @include for-sp() {
                    letter-spacing: 0.2em;
                    font-size: 1.7rem;
                }
            }
        }
    }
    .title-discription p {
        margin: 53px auto 0;
        //font-size: 1.8rem;
        font-size: 2rem;
        letter-spacing: 0.05em;
        line-height: 2;
        display: inline-block;
        text-align: left;
        @include for-tb() {
            font-size: 1.8rem;
        }
        @include for-sp() {
            font-size: 1.7rem;
            margin: 25px auto 0;
        }
    }
    .top-about {
        margin: 135px auto 235px;
        text-align: center;
        @include for-tb() {
            margin: 135px auto;
            padding: 0 30px;
        }
        @include for-sp() {
            margin: 49px auto;
        }
        .title-box {
            span {
                width: 115px;
            }
        }
        .title-discription p {
            @include for-sp() {
                margin-top: 15px;
                letter-spacing: 0.15em;
            }
        }
        .about-list {
            display: flex;
            gap: 70px;
            margin: 73px auto 31px;
            padding-left: 100px;
            padding-right: 100px;
            justify-content: center;
            flex-wrap: wrap;
            max-width: 1200px;
            @media (max-width: 1200px) {
                width: 100%;
                padding-left: 0;
                padding-right: 0;
            }
            @include for-tb(){

            }
            @include for-sp() {
                display: block;
                margin: 32px auto 200px;
                gap: 25px;
            }
            .list-item {
                width: 234px;
                @include for-sp() {
                    width: 100%;
                }
                h3 {
                    font-size: 2.7rem;
                    letter-spacing: 0.2em;
                    line-height: 1.1;
                    font-weight: 600;
                    @include for-tb() {
                        font-size: 2.5rem;
                    }
                    @include for-sp() {
                        font-size: 2.2rem;
                        order: 2;
                    }
                }
                .img {
                    height: 95px;
                    @include for-sp() {
                        order: 1;
                        height: auto;
                        margin-bottom: 15px;
                    }
                    img {
                        margin-top: 25px;
                        @include for-sp() {
                            margin-top: 0;
                        }
                    }
                }
                p {
                    margin-top: 41px;
                    font-size: 1.8rem;
                    letter-spacing: 0.1em;
                    line-height: 1.6;
                    text-align: left;
                    @include for-tb() {
                        font-size: 1.8rem;
                    }
                    @include for-sp() {
                        font-size: 1.7rem;
                        order: 3;
                        margin-top: 20px;
                        padding: 0 30px;
                        letter-spacing: 0.11em;
                    }
                }
            }
            .sumai {
                @include for-sp() {
                    margin: 0 calc(50% - 50vw);
                    width: 100vw;
                    padding: 27px 0;
                }
                img {
                    width: 72px;
                    height: 75px;
                }
            }
            .seikatsu {
                @include for-sp() {
                    margin: 0 calc(50% - 50vw);
                    width: 100vw;
                    background-color: $BGC_BLUE;
                    padding: 27px 0;
                }
                p {
                    @include for-sp() {
                        //padding: 0 60px;
                    }
                }
                img {
                    width: 84px;
                    height: 85px;
                }
            }
            .okane {
                @include for-sp() {
                    margin: 0 calc(50% - 50vw);
                    width: 100vw;
                    padding: 27px 0;
                }
                img {
                    width: 74px;
                    height: 72px;
                }
            }
            .kyoudai {
                @include for-sp() {
                    margin: 0 calc(50% - 50vw);
                    width: 100vw;
                    background-color: $BGC_BLUE;
                    padding: 27px 0;
                }
                p {
                    @include for-sp() {
                        //padding: 0 60px;
                    }
                }
                img {
                    width: 92px;
                    height: 76px;
                }
            }
            .jigyoukeishou{
                @include for-sp() {
                    margin: 0 calc(50% - 50vw);
                    width: 100vw;
                    padding: 27px 0;
                }
                p {
                    @include for-sp() {
                        //padding: 0 60px;
                    }
                }
                img {
                    width: 91px;
                    height: 75px;
                }                
            }
        }
    }
    .top-factor-support {
        position: relative;
        @include for-tb() {
            //padding: 0 30px;
        }
        .top-factor {
            width: 800px;
            height: 345px;
            position: absolute;
            border-radius: 40px;
            background-color: $WHITE;
            top: -190px;
            left: 50%;
            transform: translateX(-50%);
            padding-top: 31px;
            @include for-tb() {
                width: 600px;
                height: 340px;
                top: -100px;
                padding-top: 15px;
            }
            @include for-sp() {
                width: 345px;
                height: 375px;
                top: -175px;
            }
            &:after {
                content: "";
                border-top: 34px solid $WHITE;
                border-left: 34px solid transparent;
                border-right: 34px solid transparent;
                position: absolute;
                bottom: -28px;
                left: 50%;
                transform: translateX(-50%);
            }
            h3 {
                font-weight: 600;
                text-align: center;
                color: $FONT_BLUE;
                line-height: 1.5;
                letter-spacing: 0.25em;
                @include for-tb() {
                    font-size: 2.2rem;
                }
                @include for-sp() {
                    font-size: 2.1rem;
                    letter-spacing: 0.15em;
                }
            }
            .factor-list {
                margin-top: 38px;
                @include for-tb() {
                    margin-top: 25px;
                }
                @include for-sp() {
                    margin-top: 20px;
                }
                li {
                    //line-height: 1.5;
                    font-size: 1.8rem;
                    letter-spacing: 0.1em;
                    padding-left: 80px;
                    position: relative;
                    @include for-tb() {
                        font-size: 1.7rem;
                        padding-left: 60px;
                        padding-right: 10px;
                    }
                    @include for-sp() {
                        font-size: 1.5rem;
                        line-height: 1.6;
                        padding-left: 50px;
                        padding-right: 20px;
                        &:not(:first-child) {
                            margin-top: 20px;
                        }
                    }
                    &:before {
                        display: inline-block;
                        width: 12px;
                        height: 12px;
                        border-radius: 50%;
                        border: solid 3px $BTN_BLUE;
                        position: absolute;
                        left: 55px;
                        top: 17px;
                        content: "";
                        @include for-tb() {
                            top: 15px;
                            left: 38px;
                        }
                        @include for-sp() {
                            top: 6px;
                            left: 33px;
                        }
                    }
                }
            }
            .top-factor-appeal{
                padding-top: 75px;
                text-align: center;
                font-size: 3rem;
                color: #ed679f;
                letter-spacing: .25em;
                font-weight: 600;
                line-height: 1.5;
                @include for-sp(){
                    font-size: 2rem;
                }
            }
        }
        .top-support {
            padding: 370px 0 93px;
            background-color: $BGC_YELLOW;
            text-align: center;
            @include for-tb() {
                padding: 530px 30px 50px 30px;
            }
            @include for-sp() {
                padding: 360px 30px 25px 30px;
            }
            .title-box {
                span {
                    width: 150px;
                    &:after {
                        background-image: url("../images/titlepic-white.png");
                    }
                }
                h2 {
                    @include for-sp() {
                        font-size: 2rem;
                        line-height: 1.5;
                    }
                }
            }
            .title-discription {
                margin: 71px auto 0;
                width: 1000px;
                @include for-tb() {
                    width: 100%;
                    margin: 0 auto;
                }
                p {
                    margin-top: 0;
                    line-height: 2;
                    letter-spacing: 0.4em;
                    @include for-tb() {
                        letter-spacing: 0.2em;
                        margin-top: 53px;
                    }
                    @include for-sp() {
                        letter-spacing: 0.15em;
                        margin-top: 25px;
                    }
                    &:not(:first-child) {
                        margin-top: 61px;
                        @include for-tb() {
                            margin-top: 25px;
                        }
                    }
                }
            }
        }
        .top-support-link {
            display: flex;
            height: 550px;
            background-color: $BGC_YELLOW;
            @include for-tb() {
                //height: 970px;
                height: auto;
            }
            @include for-tb-sm(){
                display: block;
            }
            .link-item {
                display: flex;
                justify-content: center;
                align-items: center;
                //↑aタグを上下中央にセンタリングさせるため
                width: 50%;
                padding: 15px 0;
                @include for-tb-sm() {
                    width: 100%;
                    padding: 0;
                }
                .text-area {
                    position: relative;
                    width: 530px;
                    height: 80%;
                    padding: 42px 30px 28px 30px;
                    background-color:rgba(#fff,0.9);
                    border-radius: 30px;
                    transition: all 0.3s;
                    @include for-pc() {
                        width: 460px;
                    }
                    @include for-tb() {
                        width: 90%;
                        height: 100%;
                        padding: 30px 30px 28px 25px;
                        position: relative;
                        padding-bottom: 50px;
                    }
                    @include for-tb-sm() {
                        width: 100%;
                        border-radius: 0;
                        box-shadow: 0 2px 6px rgba(0, 0, 0, 0.1);
                        padding-top: 55%;
                    }
                    @include for-sp() {

                    }
                    &:hover {
                        //cursor: pointer;
                        //text-decoration: none;
                        background-color: $BGC_YELLOW;
                    }
                    h3 {
                        color: $FONT_COLOR;
                        font-weight: 600;
                        line-height: 1.5;
                        text-align: center;
                        @include for-tb() {
                            font-size: 2.2rem;
                        }
                        @include for-tb-sm(){
                            padding-top: 20px;                            
                        }
                        @include for-sp() {
                            letter-spacing: 0.05em;
                        }
                    }
                    p {
                        font-size: 1.8rem;
                        line-height: 2;
                        color: $FONT_COLOR;
                        margin-top: 10px;
                        letter-spacing: 0.1em;
                        @include for-tb-sm() {
                            //margin-top: 10px;
                        }
                        @include for-sp() {
                            letter-spacing: 0.05em;
                            font-size: 1.7rem;
                        }
                    }
                    ul{
                        line-height: 1.2;
                        margin-top: 20px;
                        padding-left: 20px;
                        li{
                            &:not(:first-child){
                                margin-top: 15px;
                            }
                            list-style: disc;
                            a{
                                color: #ED679F;
                                font-weight: 600;
                            }
                        }
                        li::marker{
                            color: #ED679F;
                        }
                    }
                    .more {
                        line-height: 1.1;
                        color: $BTN_BLUE;
                        padding-right: 28px;
                        font-size: 2rem;
                        letter-spacing: 0.2em;
                        //moreを下揃えさせる
                        position: absolute;
                        bottom: 15px;
                        right: 30px;
                        margin-top: 0;
                        @include for-sp() {
                            font-size: 1.8rem;
                            padding-right: 15px;
                        }
                        &:before {
                            content: "";
                            margin: auto;
                            position: absolute;
                            top: 0;
                            bottom: 0;
                            right: 0;
                            width: 12px;
                            height: 12px;
                            border-top: 3px solid $BTN_BLUE;
                            border-right: 3px solid $BTN_BLUE;
                            transform: rotate(45deg);
                            border-radius: 3px;
                            @include for-sp() {
                                top: -2px;
                                width: 8px;
                                height: 8px;
                                border-top: 2px solid $BTN_BLUE;
                                border-right: 2px solid $BTN_BLUE;
                                border-radius: 2px;
                            }
                        }
                        &:after {
                            content: "";
                            margin: auto;
                            position: absolute;
                            top: 0;
                            bottom: 0;
                            right: 0;
                            width: 15px;
                            height: 2px;
                            background: $BTN_BLUE;
                            border-radius: 3px;
                            @include for-sp() {
                                top: -2px;
                                width: 10px;
                                border-radius: 2px;
                            }
                        }
                    }
                }
            }
            .family {
                background-image: url("../images/bg-top-family.png");
                background-size: cover;
                background-repeat: no-repeat;
                @include for-tb-sm(){
                    background-image: none;
                }
                .text-area{
                    @include for-tb-sm(){
                        &:before{
                            content:"";
                            width: 100%;
                            height: 0; //heightでは横幅に対する高さの割合は指定できない
                            padding-top: 55%;//画像の高さ÷横幅 208px / 375px
                            position: absolute;
                            top: 0;
                            left: 0;
                            background-size:cover;
                            background-image: url("../images/bg-top-family.png");
                        }
                    }
                }
                ul{
                    padding-bottom: 35px;
                    @include for-pc(){
                        padding-bottom: 0;
                    }
                    @include for-tb(){
                        padding-bottom: 15px;
                    }
                }                
            }
            .business {
                background-image: url("../images/bg-top-business.png");
                background-size: cover;
                background-repeat: no-repeat;
                @include for-tb-sm(){
                    background-image: none;
                    margin-top: 30px;
                }
                .text-area{
                    @include for-tb-sm(){
                        &:before{
                            content:"";
                            width: 100%;
                            height: 0; //heightでは横幅に対する高さの割合は指定できない
                            padding-top: 55%;//画像の高さ÷横幅 208px / 375px
                            position: absolute;
                            top: 0;
                            left: 0;
                            background-size:cover;
                            background-image: url("../images/bg-top-business.png");
                        }
                    }
                }
            }
        }
        .casemore{
            width: 335px;
            padding: 20px 55px 20px 30px;
            font-weight: 700;
            font-size: 2.2rem;
            &:before{
                right: 50px;
            }
            &::after{
                right: 50px;
            }
            @include for-sp(){
                width: 285px;
                font-size: 1.8rem;
            }
        }
    }
    .top-online-community{
        padding-top: 100px;
        padding-bottom: 125px;
        text-align: center;
        background: linear-gradient(180deg, #FFF 40%, #F4FFE6 100%);
        position: relative;
        @include for-tb-sm(){
            padding: 70px 30px;
        }
        &::after{
            position: absolute;
            bottom: 0;
            background-image: url(../images/community-foot.png);
            background-position: bottom;
            background-repeat: repeat-x;
            content: '';   
            height: 40px;
            width: 100%;
            background-size: contain;
            left: 0;
        }        
        .title-box{
            h2{
                font-size: 2.8rem;
                letter-spacing: 0.3em;
                line-height: 2.4;
                @include for-sp() {
                    //font-size: 1.8rem;
                    font-size: 2rem;
                    line-height: 1.5;
                }
                .bgimg {
                    width: 325px;
                    @include for-sp() {
                        letter-spacing: 0.2em;
                        font-size: 1.7rem;
                    }
                    &:after{
                        @include for-sp(){
                            right: 38px;
                        }
                    }
                }
                .tit-br{
                    display: block;
                    line-height: 1;
                    @include for-sp() {
                        font-size: 2.4rem;
                        line-height: 1.5;
                    }
                }
            }
        }
        .logo-area{
            width: 486px;
            height: 177px;
            text-align: center;
            display: inline-block;
            margin-top: 25px;
            @include for-tb-sm(){
                width: 260px;
                height: 100%;
                margin-top: 10px;
            }
        }
        .contents-flex{
            display: flex;
            gap: 70px;
            margin: 80px auto 0;
            width: 1000px;
            padding: 20px;
            @media (max-width: 1000px) {
                width: auto;
            }
            @include for-tb-sm(){
                display: block;
                padding-left: 0;
                padding-right: 0;
                margin-top: 0;
                padding-top: 30px;
                padding-bottom: 30px;
            }
            .text-area{
                width: 510px;
                text-align: left;
                line-height: 2;
                @include for-tb-sm(){
                    width: 100%;
                }
                .txt-1{
                    
                }
                .txt-2{
                    padding-top: 10px;
                    .ol-list{
                        padding-top: 15px;
                        li{
                            border: none;
                            font-size: 2rem;
                            font-weight: 700;
                            padding-top: 10px;
                            padding-bottom: 10px;
                            margin-bottom: 0;
                        }
                    }
                    .txt-bg{
                        display: block;
                        font-size: 2.3rem;
                    }
                }
            }
            .img-area{
                width: 380px;
                @include for-tb-sm(){
                    display: none;
                }
                img{
                    border-radius: 25px;
                }
            }
        }
        .link-area{
            display: inline-block;
            width: 700px;
            margin: 0 auto;
            @include for-tb-sm(){
                width: 100%;
            }
            a{
                display: block;
                transition: all .3s;
                &:hover{
                    opacity: 0.7;
                }
                &:nth-child(n+2) {
                    margin-top: 20px;
                }
            }
        }
        .service-area{
            display: grid;
            grid-template-columns: 1fr 1fr;
            gap: 40px;
            width: 1000px;
            margin: 0 auto;
            padding-top: 150px;
            padding-right: 20px;
            padding-left: 20px;
            @media (max-width: 1000px) {
                width: 100%;
            }
            @include for-tb-sm(){
                grid-template-columns: 1fr;
                padding-right: 0;
                padding-left: 0;
                padding-top: 50px;
            }
            .service-img{
                height: 260px;
                @media (max-width: 1000px) {
                    height: auto;
                }
            }
            .service-tit{
                padding-top: 10px;
                h3{
                    font-size: 2.8rem;
                    text-align: center;
                    line-height: 1.5;
                    @include for-tb(){
                        font-size: 2.6rem;
                    }
                    @include for-tb-sm(){
                        font-size: 2.4rem;
                    }
                    span{
                        font-size: 2.2rem;
                        display: block;
                        @include for-tb-sm(){
                            //font-size: 1.8rem;
                            font-size: 2rem;
                        }
                    }
                }
            }
            .service-txt{
                padding-top: 25px;
                text-align: left;
                height: 420px;
                @include for-tb-sm(){
                    height: auto;
                    line-height:2;
                }
            }
            .service-link{
                padding-top: 25px;
                .casemore{
                    width: 100%;
                    height: 77px;
                    margin: 0;
                    line-height: 1.4;
                    padding: 10px;
                    @include for-tb-sm(){
                        height: 83px;
                    }
                    @include for-sp(){
                        height: 70px;
                    }
                    &::before,&::after{
                        content: none;
                    }
                    span{
                        display: block;
                    }
                }
            }
        }
    }
    .top-case {
        margin-top: 20px;
        background-image: url("../images/bg-case.png");
        background-size: 754px 750px;
        background-repeat: repeat;
        padding-bottom: 100px;
        .title-box {
            padding-top: 109px;
            text-align: center;
            @include for-tb-sm() {
                padding-top: 60px;
            }
            span {
                width: 200px;
            }
            .bgimg {
                &:after {
                    @include for-sp() {
                        right: 20px;
                    }
                }
            }
            h2 {
                @include for-sp() {
                    font-size: 2rem;
                    line-height: 1.5;
                }
            }
        }
        .case-list {
            margin-top: 77px;
            display: grid;
            grid-template-columns: 1fr 1fr 1fr;
            margin: 77px auto 0;
            gap: 35px;
            width: 1000px;
            @include for-tb() {
                grid-template-columns: 1fr;
                gap: 28px;
                width: 100%;
                padding: 0 30px;
            }
            @include for-sp() {
                margin: 30px auto 0;
            }
            .case-item {
                position: relative;
                transition: all .3s;
                padding-bottom: 30px;
                @include for-tb-sm(){
                    padding-bottom: 20px;
                }
                &:hover{
                    cursor: pointer;
                    cursor: hand;
                    opacity: 0.7;
                    text-decoration: none;
                }
                &:nth-child(2) {
                    @include for-tb() {
                        .item-head {
                            flex-direction: row-reverse;
                            right: 0;
                            h3 {
                                margin-left: 0;
                                margin-right: 13px;
                            }
                        }
                        .item-text{
                            text-align: right;
                        }
                        .item-date{
                            right: 30px;
                        }
                    }
                    @include for-sp(){
                        .item-text{
                            text-align: left;
                        }
                        .item-date{
                            right: 0;
                            left: 0;
                        }
                    }
                }
                .item-head {
                    display: flex;
                    align-items: center;
                    position: absolute;
                    top: 0;
                    left: -10px;
                    span {
                        background: linear-gradient(180deg, rgba(255, 177, 177, 1), rgba(255, 223, 185, 1));
                        width: 100px;
                        height: 100px;
                        line-height: 100px;
                        text-align: center;
                        display: inline-block;
                        border-radius: 50%;
                        font-size: 4rem;
                        color: $WHITE;
                        @include for-tb() {
                            width: 73px;
                            height: 73px;
                            font-size: 2.8rem;
                            line-height: 75px;
                        }
                    }
                    h3 {
                        margin-left: 13px;
                        font-weight: 600;
                        font-size: 2.2rem;
                        color: $FONT_COLOR;
                        line-height: 1.3;
                        @include for-tb() {
                            //font-size: 1.8rem;
                            font-size: 2rem;
                        }
                    }
                }
                .item-text {
                    font-size: 2.2rem;
                    line-height: 1.5;
                    color: $FONT_COLOR;
                    padding-top: 111px;
                    display: block;
                    font-weight: 600;
                    letter-spacing: 0.2em;
                    text-decoration: none;
                    //height: 200px;
                    @include for-tb() {
                        font-size: 1.8rem;
                        padding-left: 30px;
                        padding-top: 80px;
                        padding-right: 30px;
                        letter-spacing: 0.1em;
                        //height: 230px;
                    }
                    @include for-sp(){
                        //height: auto;
                    }
                }
                .item-date {
                    display: block;
                    line-height: 1;
                    font-size: 1.7rem;
                    color: $GRAY;
                    margin-top: 16px;
                    position: absolute;
                    bottom: 0;
                    @include for-tb() {
                        font-size: 1.2rem;
                        padding-left: 30px;
                        margin-top: 8px;
                    }
                }
            }
        }
        .ashitane {
            background-color: $BGC_GREEN;
            border-radius: 0 50px 50px 0;
            background-image: url("../images/ashitane-pic05.png");
            background-position: bottom;
            background-repeat: repeat-x;
            margin: 86px 183px 0 0;
            @include for-tb() {
                margin: 86px 45px 0 0;
            }
            @include for-tb-sm() {
                margin: 86px 15px 0 0;
            }
            @include for-sp() {
                margin: 68px 15px 0 0;
            }
            .wrapper {
                display: flex;
                align-items: center;
                padding-left: 183px;
                padding: 52px 0 111px 50px;
                justify-content: space-between;
                flex-direction: row-reverse;
                max-width: 1000px;
                margin: 0 auto;
                justify-content: space-around;
                @include for-pc() {
                    padding: 52px 40px 75px 60px;
                }
                @include for-tb() {
                    padding: 52px 40px 75px 30px;
                }
                @include for-tb-sm() {
                    display: block;
                    width: 100%;
                }
                @include for-sp() {
                    padding: 33px 0 75px 15px;
                }
                .text {
                    @include for-tb-sm() {
                        margin-top: 40px;
                        padding-left: 15px;
                        padding-right: 15px;
                    }
                    h3 {
                        font-size: 3.5rem;
                        line-height: 1.4;
                        font-weight: 600;
                        @include for-tb() {
                            font-size: 2.9rem;
                        }
                        @include for-sp() {
                            font-size: 2.6rem;
                        }
                        span {
                            font-size: 2rem;
                            display: block;
                            line-height: 1.4;
                            margin-bottom: 14px;
                            @include for-tb() {
                                //font-size: 1.8rem;
                                font-size: 2rem;
                            }
                            @include for-sp() {
                                font-size: 1.8rem;
                            }
                        }
                    }
                    p,
                    a {
                        //font-size: 1.8rem;
                        font-size: 2rem;
                        line-height: 2;
                        @include for-tb() {
                            font-size: 1.8rem;
                        }
                        @include for-sp() {
                            font-size: 1.7rem;
                        }
                    }
                    p {
                        margin-top: 30px;
                        @include for-sp() {
                            letter-spacing: 0.13em;
                        }
                    }
                }
                .logo {
                    width: 300px;
                    display: block;
                    @include for-tb-sm() {
                        margin: 0 auto;
                    }
                    @include for-sp() {
                        width: 194px;
                    }
                }
            }
        }
    }
    .top-infomation {
        background-color: $BGC_BLUE;
        //margin-top: 77px;
        @include for-tb() {
            padding: 0 30px;
            //margin-top: 40px;
        }
        @include for-tb-sm() {
            //margin-top: 27px;
        }
        .news-area,
        .sns-area {
            width: 1000px;
            display: flex;
            align-items: flex-start;
            margin: 0 auto;
            justify-content: space-between;
            @include for-tb() {
                display: block;
                width: 100%;
            }
            .title-box {
                width: 300px;
                @include for-tb() {
                    text-align: center;
                    width: 100%;
                }
                h2 {
                    line-height: 1;
                    @include for-sp() {
                        letter-spacing: 0.15em;
                    }
                }
                span {
                    font-size: 1.8rem;
                    letter-spacing: 0.15em;
                    font-weight: 500;
                    margin-bottom: 14px;
                    @include for-sp() {
                        letter-spacing: 0.2em;
                        font-size: 1.7rem;
                    }
                }
                .news-link {
                    padding: 12px 20px 11px 19px;
                    background-color: $WHITE;
                    color: $FONT_COLOR;
                    border-radius: 10px;
                    border: 1px solid #d8d8d8;
                    font-size: 1.8rem;
                    line-height: 1.2;
                    margin-top: 72px;
                    display: inline-block;
                    transition: all 0.3s;
                    @include for-tb() {
                        display: none;
                    }
                    &:hover {
                        background-color: $BGC_YELLOW;
                        border: 1px solid $BGC_YELLOW;
                        text-decoration: none;
                    }
                }
            }
        }
        .news-area {
            padding: 66px 0 69px;
            border-bottom: 1px solid #c3c3c3;
            @include for-sp() {
                padding: 66px 0 45px;
            }
            .title-box {
                span {
                    width: 155px;
                    margin: 0;
                    margin-bottom: 14px;
                    @include for-tb() {
                        margin: auto;
                        margin-bottom: 14px;
                    }
                    &:after {
                        background-image: url("../images/titlepic-white.png");
                        right: -24px;
                        @include for-sp() {
                            right: -8px;
                        }
                    }
                }
            }
            .news-list {
                width: 641px;
                @include for-tb() {
                    width: 100%;
                    margin-top: 40px;
                }
                span {
                    display: block;
                    line-height: 1;
                    font-size: 1.7rem;
                    color: $GRAY;
                    margin-bottom: 10px;
                    @include for-sp() {
                        font-size: 1.4rem;
                    }
                }
                a {
                    font-size: 1.8rem;
                    @include for-sp() {
                        font-size: 1.7rem;
                    }
                }
                li {
                    font-size: 1.8rem;
                    line-height: 1.5;
                    letter-spacing: 0.13em;
                    @include for-sp() {
                        font-size: 1.7rem;
                    }
                    &:not(:first-child) {
                        margin-top: 32px;
                    }
                }
            }
            .news-link-sp {
                display: none;
                @include for-tb() {
                    display: block;
                    text-align: center;
                    background-color: $BTN_BLUE;
                    color: $WHITE;
                    padding: 20px 89px 20px 78px;
                    position: relative;
                    width: 252px;
                    border-radius: 15px;
                    margin: 51px auto 0;
                    font-size: 2rem;
                    line-height: 1;
                    letter-spacing: 0.2em;
                    transition: all 0.3s;
                    @include for-tb-sm() {
                        font-size: 1.8rem;
                        padding: 20px 50px 19px 30px;
                    }
                    @include for-sp() {
                        margin: 28px auto 0;
                        width: 234px;
                    }
                    &:hover {
                        opacity: 0.8;
                        text-decoration: none;
                        background-color: $FONT_BLUE;
                    }
                    &:before {
                        content: "";
                        margin: auto;
                        position: absolute;
                        top: 0;
                        bottom: 0;
                        right: 75px;
                        width: 12px;
                        height: 12px;
                        border-top: 3px solid $WHITE;
                        border-right: 3px solid $WHITE;
                        transform: rotate(45deg);
                        border-radius: 3px;
                        @include for-sp() {
                            width: 10px;
                            height: 10px;
                            border-top: 2px solid $WHITE;
                            border-right: 2px solid $WHITE;
                            border-radius: 2px;
                        }
                    }
                    &:after {
                        content: "";
                        margin: auto;
                        position: absolute;
                        top: 0;
                        bottom: 0;
                        right: 75px;
                        width: 15px;
                        height: 2px;
                        background: $WHITE;
                        border-radius: 3px;
                        @include for-sp() {
                            width: 13px;
                            border-radius: 2px;
                        }
                    }
                }
            }
        }
        .sns-area {
            padding: 71px 0 86px;
            @include for-sp() {
                padding: 35px 0 76px;
            }
            .title-box {
                p {
                    font-size: 2rem;
                    line-height: 1.6;
                    margin-top: 32px;
                    @include for-sp() {
                        font-size: 1.8rem;
                        margin-top: 25px;
                    }
                }
            }
            .sns-list {
                width: 641px;
                display: grid;
                grid-template-columns: 1fr 1fr;
                //column-gap: 40px;
                row-gap: 20px;
                @include for-tb() {
                    width: 100%;
                    //column-gap: 20px;
                    margin-top: 40px;
                }
                @include for-tb-sm() {
                    //grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
                    //row-gap: 0;
                    //column-gap: 0;
                    margin: 30px auto 0;
                    display: flex;
                    justify-content: space-around;                    
                }
                @include for-sp(){
                    justify-content: space-between;
                }
                a {
                    font-size: 1.8rem;
                    font-weight: 600;
                    border-radius: 10px;
                    background-color: $WHITE;
                    border: 1px solid #d8d8d8;
                    height: 80px;
                    line-height: 4.2;
                    padding-left: 89px;
                    width: 300px;
                    display: inline-block;
                    color: $FONT_COLOR;
                    transition: all 0.3s;
                    @include for-tb() {
                        //left: 40px;
                        margin-left: 40px;
                    }
                    @include for-tb-sm() {
                        background-color: transparent;
                        width: 44px;
                        border: none;
                        //left: 0;
                        margin-left: 0;
                        padding-left: 0;
                        height: 44px;
                    }
                    &:hover {
                        background-color: $BGC_YELLOW;
                        border: 1px solid $BGC_YELLOW;
                        text-decoration: none;
                        @include for-tb-sm() {
                            background-color: transparent;
                            border: none;
                        }
                    }
                }
                .facebook,
                .twitter,
                .instagram,
                .youtube,
                .line {
                    a {
                        position: relative;
                        &:before {
                            position: absolute;
                            background-repeat: no-repeat;
                            background-size: contain;
                            width: 46px;
                            height: 46px;
                            top: 18px;
                            left: 20px;
                            content: "";
                            @include for-tb-sm() {
                                width: 100%;
                                height: 100%;
                                //left: 40px;
                                left: 0;
                            }
                            @include for-sp(){
                                //left: 0;
                            }
                        }
                    }
                    span {
                        @include for-tb-sm() {
                            display: none;
                        }
                    }
                }
                .facebook {
                    a {
                        &:before {
                            top: 15px;
                            background-image: url("../images/facebook.png");
                            @include for-tb-sm() {
                                top: 0;
                            }
                        }
                    }
                }
                .twitter {
                    a {
                        &:before {
                            height: 34px;
                            top: 21px;
                            background-image: url("../images/x.png");
                            @include for-tb-sm() {
                                top: 7px;
                            }
                        }
                    }
                }
                .instagram {
                    a {
                        &:before {
                            background-image: url("../images/instagram.png");
                            @include for-tb-sm() {
                                top: 0;
                            }
                        }
                    }
                }
                .youtube {
                    a {
                        &:before {
                            height: 32px;
                            top: 21px;
                            background-image: url("../images/youtube.png");
                            @include for-tb-sm() {
                                top: 7px;
                            }
                        }
                    }
                }
                .line {
                    a {
                        &:before {
                            background-image: url("../images/line.png");
                            @include for-tb-sm() {
                                top: 0;
                            }
                        }
                    }
                }
            }
        }
    }
}
.casemore {
    display: block;
    text-align: center;
    background-color: $BTN_BLUE;
    color: $WHITE;
    padding: 20px 89px 20px 78px;
    position: relative;
    width: 252px;
    border-radius: 15px;
    margin: 51px auto 0;
    font-size: 2rem;
    line-height: 1;
    letter-spacing: 0.2em;
    transition: all 0.3s;
    @include for-tb-sm() {
        font-size: 1.8rem;
        padding: 20px 50px 19px 30px;
    }
    @include for-sp() {
        margin: 28px auto 0;
        width: 234px;
    }
    &:hover {
        opacity: 0.8;
        text-decoration: none;
        background-color: $FONT_BLUE;
    }
    &:before {
        content: "";
        margin: auto;
        position: absolute;
        top: 0;
        bottom: 0;
        right: 75px;
        width: 12px;
        height: 12px;
        border-top: 3px solid $WHITE;
        border-right: 3px solid $WHITE;
        transform: rotate(45deg);
        border-radius: 3px;
        @include for-sp() {
            width: 10px;
            height: 10px;
            border-top: 2px solid $WHITE;
            border-right: 2px solid $WHITE;
            border-radius: 2px;
        }
    }
    &:after {
        content: "";
        margin: auto;
        position: absolute;
        top: 0;
        bottom: 0;
        right: 75px;
        width: 15px;
        height: 2px;
        background: $WHITE;
        border-radius: 3px;
        @include for-sp() {
            width: 13px;
            border-radius: 2px;
        }
    }
}
.ol-list {
    list-style: none;
    padding: 0;
    font-size: 2.4rem;
    line-height: 1.5;
    @include for-tb() {
        font-size: 2rem;
    }
    @include for-sp() {
        font-size: 1.8rem;
    }
    li {
        padding: 25px 0;
        margin-bottom: 15px;
        padding-left: 65px;
        position: relative;
        border: 2px solid #222;
        border-radius: 5px;
        background: #fff;
        @include for-tb() {
            padding: 25px 10px 25px 65px;
        }
        @include for-sp() {
            padding: 15px 10px 15px 40px;
        }
        &:nth-child(2n) {
            .ol-number {
                color: #0096e0;
                background: -webkit-linear-gradient(-90deg, #6fb16c, #0096e0);
                -webkit-background-clip: text;
                -webkit-text-fill-color: transparent;
            }
        }
    }
    .ol-number {
        font-family: Roboto;
        font-weight: 600;
        position: absolute;
        left: 0;
        top: 50%;
        transform: translateY(-50%);
        width: 65px;
        text-align: center;
        font-size: 6rem;
        line-height: 1;
        color: #e84091;
        background: -webkit-linear-gradient(-90deg, #e84091, #f1d32d);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        @include for-sp() {
            font-size: 4rem;
            width: 40px;
        }
    }
}