@use "../global" as *;
.paginate{
    margin-top: 115px;
    @include for-sp(){
        margin-top: 75px;
    }
    .paginate-list{
        display: flex;
        justify-content: center;
        align-items: baseline;
        flex-wrap: wrap;
        list-style: none;
        li{
            margin:0 4px;
            a{
                display: inline-block;
                width: 34px;
                height: 34px;
                line-height: 33px;
                text-align: center;
                transition: all .3s;
                &:hover{
                    color: $WHITE;
                    background-color: $FONT_RIGHT_BLUE;
                    opacity: 0.8;
                    text-decoration: none;
                }
            }
            .number{
                font-family: 'Roboto', sans-serif;
                font-size: 1.7rem;
                letter-spacing: 0;
                
            }
        }
        .current{
            background-color: $FONT_RIGHT_BLUE;
            color: $WHITE;
            pointer-events: none;
            width: 34px;
            height: 34px;
            line-height: 30px;
            text-align: center;
        }
        .prev{
            margin-right: 40px;
            @include for-sp(){
                margin-right: 25px;
            }
        }
        .next{
            margin-left: 40px;
            @include for-sp(){
                margin-left: 25px;
            }
        }
        .prev,.next{
            svg{
                vertical-align: baseline;
                g{
                    line{
                        stroke:$GRAY;	
                    }
                }
            }
            a{
                svg{
                    g{
                        line{
                            stroke:$FONT_COLOR;	
                        }
                    }
                }
                &:hover{
                    svg{
                        g{
                            line{
                                stroke:$WHITE;	
                            }
                        }
                    }
                }
            }
        }        
    }
}