@use "../global" as *;
footer {
    background-color: $DARK_BLUE;
    padding: 40px 40px 20px 40px;
    color: $BGC_GRAY;
    font-size: 1.6rem;
    position: relative;
    @include for-tb(){
        padding: 22px 30px 15px 30px;
    }
    @include for-sp(){
        padding-bottom: 90px;
    }
    a,p{
        color: $BGC_GRAY;
        font-size: 1.6rem;
    }
    a{
        display: inline-block;
        line-height: 2;
    }
    .foot-wrapper{
        display: grid;
        grid-template-columns: 500px 1fr;
        gap: 65px;
        @media (max-width: 1300px) {
            gap: 30px;
        }
        @include for-tb(){
            display: block;
        }
    }
    .foot-left{
        .foot-logo{
            img{
                width: 263px;
                height: 55px;
                @include for-sp(){
                    width: 220px;
                    height: 46px;
                }
            }
        }
        .foot-address{
            .foot-name{
                margin-bottom: 16px;
                font-size: 1.7rem;
                line-height: 1.8;
                @include for-sp(){
                    font-size: 1.8rem;
                }
            }
            padding-left: 63px;
            @include for-tb(){
                padding-left: 0;
            }
            .foot-address-info{
                font-size: 1.5rem;
            }
            p{
                &:last-child{
                    margin-top: 5px;
                }
                margin-top: 23px;
            }
            span{
                display: block;
                line-height: 1.1;
            }
            .txt-break{
                padding-top: 10px;
                padding-bottom: 10px;
                @include for-sp(){
                    display: inline-block;
                    line-height: 1.2;
                    padding-bottom: 0;
                }
            }
        }
    }
    .foot-center{
        margin-top: 75px;
        text-align: right;
        @include for-tb(){
            text-align: left;
        }
        @include for-tb-sm(){
            margin-top: 30px;
        }
        .list{
            display: grid;
            grid-template-columns: 150px 210px 160px;
            text-align: left;
            gap: 32px;
            @media (max-width: 1300px) {
                column-gap: 15px;
                row-gap: 0;
                grid-template-columns: 170px 1fr;
            }
            @include for-tb(){
            }
            @include for-sp(){
                display: block;
                gap: 0;
            }
        }
    }
    .foot-right{
        background-color: #fff;
        position: fixed;
        width: 230px;
        right: 20px;
        bottom: 0;
        border-radius: 10px 10px 0 0;
        @include for-sp(){
            width: 320px;
            right: 0;
            left: 0;
            margin: 0 auto;
        }
        a{
            color: $FONT_COLOR;
            font-weight: 700;
            font-size: 1.7rem;
            letter-spacing: 0.1em;
            padding: 15px;
            transition: all .3s;
            border-radius: 10px 10px 0 0;
            box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.1);
            @include for-sp(){
                font-size: 1.2rem;
                letter-spacing: 0.2em;
                padding: 10px 20px;
                display: grid;
                grid-template-columns: 1fr 115px;
                gap: 11px;
                align-items: center;
            }
            &:hover{
                background-color: #f4ffe6;
                text-decoration: none;
            }
        }
        img{
            width: 170px;
            padding-top: 10px;
            display: block;
            margin: 0 auto;
            @include for-sp(){
                width: 130px;
                padding-top: 0;
            }
        }
    }
    .copyright{
        display: block;
        padding-top: 40px;
        padding-left: 63px;
        @include for-tb(){
            padding-left: 0;
        }
        @include for-sp(){
            padding-top: 30px;
            text-align: center;
            font-size: 1rem;
            letter-spacing: 0.07em;
            display: grid;
        }
    }
}

#pagetop{
   position: fixed;
   right: 10px;
   bottom: 330px;
   z-index: 100;
   display: none;
   width: 40px;
   @include for-sp(){
    right: 0;
   }
   a{
       display: block;
       writing-mode: vertical-rl;
       font-size: 1.6rem;
       color: #444444;
       transition: all .3s;
       &:before{
        content: "";
        height: 0;
        width: 1px;
        background: #444444;
        position: absolute;
        left: 16px;
        top: 0;        
        animation: pathmove 3s ease-in-out infinite;
       }
       &:hover{
        opacity: 0.6;
        text-decoration: none;
       }
       @include for-sp(){
        &:before{
            font-size: 1.4rem;
        }
       }
    }
}
@keyframes pathmove{
    0%{
      height: 0;
      top: -10px;
      opacity: 0;
    }
    50%{
      height: 60px;
      top: -85px;
      opacity: 1;
    }
    100%{
      height: 0;
      top: -85px;
      opacity: 0;
    }
}