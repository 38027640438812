@use "../global" as *;
//下層共通
.sub {
    .head-wrap {
        position: relative;
        height: 474px;
        /*background: linear-gradient(
            180deg,
            rgba(197, 226, 250, 0.5),
            rgba(187, 234, 253, 0.7),
            rgba(250, 223, 224, 0.9)
        );*/
        background: url('../images/key_img.webp');
        background-size: cover;
        background-repeat: no-repeat;        
        @include for-sp() {
            height: 300px;
        }
    }
    .head-img {
        position: absolute;
        mix-blend-mode: overlay;
    }
    .key {
        background-image: url("../images/img-subhead.png");
        background-size: cover;
        background-repeat: no-repeat;
        height: 340px;
        margin: 0 0 0 88px;
        top: 100px;
        border-radius: 80px 0 0 80px;
        z-index: 2;
        position: relative;
        background-position: right center;
        display: flex;
        justify-content: center;
        align-items: center;
        @include for-sp() {
            margin-left: 12px;
            height: 203px;
            top: 70px;
            border-radius: 30px 0 0 30px;
            background-position: center;
        }
        .key-wrapper {
            background-color: $WHITE;
            opacity: 0.9;
            padding: 50px 72px;
            border-radius: 30px;
            width: 650px;
            text-align: center;
            @include for-tb() {
                width: 600px;
            }
            @include for-tb-sm() {
                width: 530px;
            }
            @include for-sp() {
                padding: 28px 20px;
                width: 340px;
            }
            .key-title {
                h1,p {
                    font-size: 3.6rem;
                    letter-spacing: 0.1em;
                    line-height: 1.4;
                    position: relative;
                    width: fit-content;
                    margin: 0 auto;
                    font-weight: 600;
                    &:before {
                        content: "";
                        position: absolute;
                        top: 50%;
                        transform: translateY(-50%);
                        left: -50px;
                        background-image: url("../images/titlepic-pink.png");
                        width: 35px;
                        height: 35px;
                        background-size: cover;
                        z-index: -1;
                        @include for-tb() {
                            width: 27px;
                            height: 27px;
                            left: -40px;
                        }
                        @include for-tb-sm() {
                        }
                        @include for-sp() {
                            width: 18px;
                            height: 18px;
                            left: -25px;
                        }
                    }
                    &:after {
                        content: "";
                        position: absolute;
                        top: 50%;
                        transform: translateY(-50%);
                        right: -50px;
                        background-image: url("../images/titlepic-pink.png");
                        width: 35px;
                        height: 35px;
                        background-size: cover;
                        z-index: -1;
                        @include for-tb() {
                            width: 27px;
                            height: 27px;
                            right: -40px;
                        }
                        @include for-tb-sm() {
                        }
                        @include for-sp() {
                            width: 18px;
                            height: 18px;
                            right: -25px;
                        }
                    }
                    @include for-tb() {
                        font-size: 3rem;
                    }
                    @include for-sp() {
                        font-size: 2.4rem;
                        text-align: center;
                    }
                    span {
                        font-size: 2.4rem;
                        display: block;
                        text-align: center;
                        @include for-sp() {
                            font-size: 1.8rem;
                            padding-bottom: 10px;
                        }
                    }
                    .bgimg {
                        font-size: 2rem;
                        letter-spacing: 0.2em;
                        line-height: 1;
                        display: block;
                        color: $FONT_RIGHT_BLUE;
                        position: relative;
                        margin: 0 auto;
                        z-index: 0;
                        width: fit-content;
                        @include for-sp() {
                            font-size: 1.4rem;
                        }
                        &:after {
                            content: "";
                            position: absolute;
                            top: -20px;
                            right: -20px;
                            background-image: url("../images/titlepic-pink.png");
                            width: 35px;
                            height: 35px;
                            background-size: cover;
                            z-index: -1;
                            @include for-sp() {
                                top: -14px;
                                right: -10px;
                                width: 21px;
                                height: 21px;
                            }
                        }
                    }
                }
            }
        }
    }
    .sns {
        position: absolute;
        bottom: 0;
        left: 0;
        display: grid;
        //background-color: #fff;
        //border-radius: 0 10px 0 0;
        padding: 13px;
        grid-template-columns: 1fr;
        justify-items: center;
        @include for-pc() {
            display: none;
        }
        p {
            font-size: 1.1rem;
            letter-spacing: 0.2em;
            line-height: 1;
        }
        .sns-list {
            width: 26px;
            margin-top: 20px;
        }
    }
    .contents {
        margin: 0 auto;
        padding-bottom: 100px;
        @include for-sp() {
            padding-bottom: 15px;
        }
        h2 {
            font-size: 3.2rem;
            line-height: 1.4;
            letter-spacing: 0.03em;
            position: relative;
            text-align: center;
            font-family: "筑紫A丸ゴシック", "Chikushimaru Gothic", sans-serif;
            margin-bottom: 50px;
            padding-top: 30px;
            &:not(:first-child) {
                padding-top: 100px;
                @include for-tb-sm() {
                    padding-top: 60px;
                }
                @include for-sp() {
                    padding-top: 40px;
                }
            }
            @include for-tb() {
                font-size: 2.6rem;
            }
            @include for-sp() {
                font-size: 2.3rem;
                margin-bottom: 40px;
            }
            &:after {
                position: absolute;
                width: 80px;
                height: 2px;
                background: linear-gradient(to right, #ed679f, #ffd550, #19d979, #1ca6d0);
                content: "";
                left: 50%;
                transform: translateX(-50%);
                bottom: -5px;
                @include for-sp() {
                    width: 55px;
                }
            }
        }
        h3 {
            padding-top: 30px;
            padding-bottom: 30px;
            @include for-sp() {
                line-height: 1.5;
            }
        }
        p {
            line-height: 2;
        }
        p,
        ul,
        ol,
        dl,
        h4 {
            margin-bottom: 32px;
            @include for-sp() {
                line-height: 1.7;
            }
        }
        dt,
        dd {
            @include for-sp() {
                line-height: 1.7;
            }
        }
        dd {
            @include for-sp() {
                margin-bottom: 10px;
            }
        }
        table {
            margin-bottom: 32px;
        }
        .block {
            width: 1080px;
            padding: 55px 40px;
            margin: 0 auto;
            @include for-pc() {
                width: 100%;
            }
            @include for-tb-sm() {
                padding: 30px;
            }
        }
        h4{
            font-weight: 600;
        }
        .btn-link{
            border-radius: 35px;
            padding: 15px 50px;
            background: $BGC_ORANGE;
            display: table;
            margin: 0 auto;
            line-height: 1.5;
            transition: all .3s;
            color: $WHITE;
            @include for-sp(){
                padding: 10px 25px;
            }
            &:hover{
                text-decoration: none;
                opacity: 0.8;
            }
        }
        .btn-arrow-right-warp{
            padding: 15px 50px 15px 35px;
            @include for-sp(){
                padding: 10px 30px;
            }
        }
        .btn-arrow-right{
            position: relative;
            &:before {
                content: "";
                margin: auto;
                position: absolute;
                top: 0;
                bottom: 0;
                right: -25px;
                width: 12px;
                height: 12px;
                border-top: 3px solid $WHITE;
                border-right: 3px solid $WHITE;
                transform: rotate(45deg);
                border-radius: 3px;
                @include for-sp() {
                    width: 10px;
                    height: 10px;
                    border-top: 2px solid $WHITE;
                    border-right: 2px solid $WHITE;
                    border-radius: 2px;
                }
            }
            &:after {
                content: "";
                margin: auto;
                position: absolute;
                top: 0;
                bottom: 0;
                right: -25px;
                width: 15px;
                height: 2px;
                background: $WHITE;
                border-radius: 3px;
                @include for-sp() {
                    width: 13px;
                    border-radius: 2px;
                }
            }
        }
        /*h3{
            padding: .5em .7em;
            border-left: 5px solid #2589d0;
            border-bottom: 3px solid #d2d2d2;
            background-color: #f2f2f2;
        }
        h4 {
            position: relative;
            padding-left: 30px;
            font-weight: 600;
            &:before{
                content: "";
                position: absolute;
                left: 0;
                top: 50%;
                transform: translateY(-50%);
                width: 20px;
                height: 20px;
                background: conic-gradient(from 0deg, #ed679f, #ffd550, #19d979, #1ca6d0, #ed679f);
                border-radius: 50%;
            }
        }*/
        .definition-question{
            .definition-wrap{
                border: solid 1px $FONT_COLOR;
                border-radius: 15px;
                padding: 15px 25px;
                margin-bottom: 25px;
                @include for-sp(){
                    padding: 10px 17px 0px;
                }
            }
        }
        .flow-list{
            list-style: none;
            padding: 0;
            font-size: 2.4rem;
            line-height: 1.5;
            @include for-tb(){
                font-size: 2rem;
            }
            @include for-sp(){
                font-size: 1.5rem;
            }
            li{
                padding: 25px 0;
                margin-bottom: 15px;
                padding-left: 80px;
                padding-right: 35px;
                position: relative;
                border: 2px solid #222;
                border-radius: 5px;
                background: #fff;
                margin-bottom: 100px;
                &:after{
                    transform: rotate(90deg);
                    position: absolute;
                    content: '';
                    border: solid 35px transparent;
                    border-left: solid 35px #B4D0D3;
                    bottom: -105px;
                    right: 46%;
                    @include for-sp(){
                        right: 44%;
                        border: solid 20px transparent;
                        border-left: solid 20px #B4D0D3;
                        bottom: -65px;
                    }
                }
                &:last-child{
                    margin-bottom: 0;
                    &:after{
                        content: none;
                    }
                }
                @include for-tb(){
                    padding: 25px 10px 25px 65px;                    
                }
                @include for-sp(){
                    padding: 15px 10px 15px 40px;
                    margin-bottom: 60px;
                }
                &:nth-child(2n){
                    .flow-number{
                        color: #0096e0;
                        background: -webkit-linear-gradient(-90deg,#6fb16c,#0096e0);
                        -webkit-background-clip: text;
                        -webkit-text-fill-color: transparent;                        
                    }
                }
            }
            .flow-flex{
                display: flex;
                .flow-text{
                    margin-left: 20px;
                    @include for-sp(){
                        margin-left: 0;
                    }
                }
            }
            .flow-number{
                font-family: Roboto;
                font-weight: 600;
                position: absolute;
                left: 20px;
                top: 50%;
                transform: translateY(-50%);
                width: 65px;
                text-align: center;
                font-size: 6rem;
                line-height: 1;
                color: #e84091;
                background: -webkit-linear-gradient(-90deg,#e84091,#f1d32d);
                -webkit-background-clip: text;
                -webkit-text-fill-color: transparent;
                @include for-sp(){
                    font-size: 4rem;
                    width: 40px;
                    left: 0;
                }
            }
            .flow-text{
                font-size: 1.8rem;
                @include for-sp(){
                    font-size: 1.4rem;
                }
            }
            .flow-title{
                display: block;
                font-weight: 600;
                margin-bottom: 20px;
                font-size: 2.4rem;
                @include for-sp(){
                    margin-bottom: 10px;
                    font-size: 1.8rem;
                }
            }
        }        
    }
}
/*===============================
	reCAPTCHA
=================================*/

.grecaptcha-badge {
    bottom: 85px !important;
}
