@use "../global" as *;
.breadcrumb{
    padding: 20px 40px;
    width: 1080px;
    margin: 0 auto;
    @include for-tb-sm(){
        display: none;
    }
    ul{
        display: flex;
        list-style: none;
        flex-wrap: wrap;
        li{
            font-size: 1.2rem;
            @include for-sp(){
                font-size: 1rem;
            }
            &:after{
                content: "/";
                color: $GRAY;
                margin: auto 7px;
                @include for-sp(){
                    margin: auto 1px;
                }
            }
            &:last-child{
                &:after{
                    content: "";
                }
            }
            a{
                color: $FONT_COLOR;
                font-size: 1.2rem;
                text-decoration: underline;
                transition: all .3s;
                &:hover{
                    opacity: 0.8;
                }
            }
            @include for-sp(){
                font-size: 1rem;
            }
            img{
                width: 23px;
                height: 21px;
            }
            .home{
                text-decoration: none;
            }
        }
    }
}